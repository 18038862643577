import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { HelmetComponent } from '../lib/Components';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Settings = () => {
    const [loading, setLoading] = useState(false);


    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Settings" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>

                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>

                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <PeopleAltIcon style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Settings</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <Button onClick={() => setLoading(!loading)} startIcon={<Refresh />} size="small" color="primary"  >Refresh</Button>
                            </Stack>
                        </Stack>
                        settings

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                            open={loading}
                            onClick={() => setLoading(false)}
                        >
                            <CircularProgress color="info" size={20} />
                        </Backdrop>

                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}

export default React.memo(Settings);