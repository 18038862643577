import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { theme } from '../../theme/authlify-mui-theme';
import { formatStringLang, getLevelInfo, PrettyPrintJson, formatString } from '../../lib/Tools';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { Waypoint } from 'react-waypoint'
import LinearProgress from '@mui/material/LinearProgress';

const QueryTable = React.memo(({ data, onWaypointEnter, loading }) => {
    return (
        <Box>
            <StyledTable sx={{ display: { lg: 'block', xs: 'none' } }}>
                <Table aria-label="query table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" style={{ width: 80 }}></TableCell>
                            <TableCell component="th" style={{ width: 210 }}>TIMESTAMP</TableCell>
                            <TableCell component="th" style={{ width: 150 }}>HOST (AGENT)</TableCell>
                            <TableCell component="th" style={{ width: 100 }}>EVENT ID</TableCell>
                            <TableCell component="th">SUMMARY</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <QueryResultRowDesktop key={index} row={row} />
                        ))}
                        {data.length === 0 ?
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }} colSpan={5}><Box sx={{ p: 3 }} color="text.secondary">No data to display.</Box></TableCell>
                            </TableRow>
                            : null}
                    </TableBody>
                </Table>
                {loading ? <LinearProgress /> : null}
            </StyledTable>
            <StyledTable sx={{ display: { lg: 'none', xs: 'block' } }}>
                <Table aria-label="query table" size="small">
                    <TableBody>
                        {data.map((row, index) => (
                            <QueryResultRowMobile key={index} row={row} />
                        ))}
                        {data.length === 0 ?
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }} colSpan={5}><Box sx={{ p: 3 }} color="text.secondary">No data to display.</Box></TableCell>
                            </TableRow>
                            : null}
                    </TableBody>
                </Table>
                {loading ? <LinearProgress /> : null}
            </StyledTable>
            {data && data.length > 0 ? <Waypoint onEnter={() => onWaypointEnter()} bottomOffset={-200} /> : null}
        </Box >
    )
});

const QueryResultRowDesktop = React.memo(({ row }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(false)
    }, [row])
    return (
        <React.Fragment>
            <TableRow>
                <TableCell sx={{ pr: 0, pt: 1, fontFamily: 'Roboto Mono' }}>
                    <Stack direction="row" spacing={1} alignItems="center" >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                        {getLevelInfo(row.level).icon}
                    </Stack>
                </TableCell>
                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                    <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                </TableCell>
                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                    {row.hostname}
                </TableCell>
                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                    {row.event_id}
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" style={{ lineHeight: 2, fontWeight: 'bold' }}>{row.definition ? formatString(formatStringLang(row.definition.localized, 'name', 'cs', row.data)) : `Event ID: ${row.event_id}`}</Typography>
                    <Typography variant="subtitle2" style={{ fontFamily: 'Roboto Mono' }}>{row.definition ? formatString(formatStringLang(row.definition.localized, 'description', 'cs', row.data)) : ``}</Typography>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box ml={3}>
                            <PrettyPrintJson data={row} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
});

const QueryResultRowMobile = React.memo(({ row }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(false)
    }, [row])
    return (
        <React.Fragment>
            <TableRow>
                <TableCell >
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: theme.typography.pxToRem(12), mb: 1, fontFamily: 'Roboto Mono', }} >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                        {getLevelInfo(row.level).icon}
                        <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>EVENT ID:</Typography>
                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>{row.event_id}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>HOST:</Typography>
                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>{row.hostname}</Typography>
                    </Stack>
                    <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
                        <Typography variant="subtitle2" style={{ lineHeight: 2, fontWeight: 'bold', fontSize: theme.typography.pxToRem(12) }}>{row.definition ? formatString(formatStringLang(row.definition.localized, 'name', 'cs', row.data)) : `Event ID: ${row.event_id}`}</Typography>
                        <Typography variant="subtitle2" style={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>{row.definition ? formatString(formatStringLang(row.definition.localized, 'description', 'cs', row.data)) : ``}</Typography>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box ml={3}>
                            <PrettyPrintJson data={row} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
});

const StyledTable = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188'
    },
    '& tbody tr': {
        verticalAlign: 'top',

    },
    '& tbody tr td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td:not(:first-child),& tbody tr td:not(:first-child) *': {
        fontSize: theme.typography.pxToRem(12),
    },
    '& tbody tr:nth-child(odd) td': {
        borderBottom: 0,
    },
    '& tbody tr:nth-child(even) td': {
        paddingBottom: 0,
        paddingTop: 0
    },
    '& tbody tr td:first-child': {
        paddingTop: 4
    },
}));

export default QueryTable;

