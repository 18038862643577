import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DashboardRounded from '@mui/icons-material/DashboardRounded';
import NotificationsActiveRounded from '@mui/icons-material/NotificationsActiveRounded';
import FindInPage from '@mui/icons-material/FindInPage';
import LogoWhite from '../assets/LogoWhite.svg';
import { authlifyTheme, theme } from '../theme/authlify-mui-theme';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Explore from './explore/';
import Events from './Events';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import FlashOn from '@mui/icons-material/FlashOn';
import SendRounded from '@mui/icons-material/SendRounded';
import PersonIcon from '@mui/icons-material/Person';
import { post as ApiPost } from '../lib/Api';
import Triggers from './Triggers';
import Trigger from './Trigger';
import Definition from './Definition';
import Definitions from './Definitions';
import Action from './Action';
import Actions from './Actions';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PrintIcon from '@mui/icons-material/Print';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ComputerIcon from '@mui/icons-material/Computer';
import Printers from './Printers';
import Logs from './Logs';
import ArticleIcon from '@mui/icons-material/Article';
import Users from './Users';
import User from './User';
import Endpoints from './Endpoints';
import Printer from './Printer';
import Settings from './Settings';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Search from './components/Search';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function Main() {
    const classes = useStyles();
    const [user, setUser] = useState();
    const [toLogin, goToLogin] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const open = Boolean(anchorEl);
    const [openSearchBox, setOpenSearchBox] = useState(false);
    const history = useHistory()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // nastavim uzivatele, pokud neprojde overeni, smeruju na login
    useEffect(() => {
        async function fetchData() {
            const user = await ApiPost(`main`);
            user.status === 200 ? setUser(user.data) : goToLogin(true);
        }
        fetchData();
    }, [])

    // detekuje zmeny adresy
    useEffect(() => {
        return history.listen((location) => {
            // uzavru search box
            setOpenSearchBox(false);
        })
    }, [history])

    const logout = async () => {
        const response = await ApiPost(`logout`);
        if (response.status === 200) {
            setUser(null);
            goToLogin(true);
        }
    }

    if (toLogin) {
        return <Redirect to={`/login`} />
    }

    if (user) {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <StyledAppBar position="sticky" elevation={0} color="header">
                    <Toolbar style={{ minHeight: 0, paddingLeft: 0, paddingRight: theme.spacing(1), borderBottom: '1px solid rgb(0 0 0 / 5%)' }}>
                        <Button sx={{ display: { lg: 'initial', xs: 'none' } }} style={{ backgroundColor: theme.palette._blue.main, borderRadius: 0, marginRight: theme.spacing(2), minHeight: authlifyTheme.header }}>
                            <img alt='Authlify' src={LogoWhite} style={{ height: 24, marginBottom: -3 }} />
                        </Button>
                        <Button onClick={() => setMobileOpen(true)} sx={{ display: { lg: 'none', xs: 'initial' } }} style={{ backgroundColor: theme.palette._blue.main, borderRadius: 0, marginRight: theme.spacing(2), minHeight: authlifyTheme.header }}>
                            <MenuIcon color="white" />
                        </Button>
                        <Box mr={2} sx={{ flexGrow: { lg: 0, xs: 1 } }}>
                            <Typography variant="subtitle2" component="div" style={{ fontWeight: 700 }}>Authlify</Typography>
                            <Typography sx={{ color: '#647188', fontWeight: 500, flexGrow: 1, display: { sm: 'block', xs: 'block' } }} variant="caption" component="div">Hortim International Group</Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { lg: 'block', xs: 'none' } }}>
                            <Stack direction="row" spacing={0} alignItems="center">
                                <NavLinkSimple to={'/dashboard'} label="Dashboard" icon={<DashboardIcon />} />
                                <Divider orientation="vertical" flexItem variant="middle" sx={{ mt: 2, mb: 2 }} />
                                <NavLinkSimple to={'/logs'} label="Logs" icon={<ArticleIcon />} />
                                <NavLinkSimple to={'/events'} label="Events" icon={<NotificationsActiveRounded />} />
                                <NavLinkSimple to={'/explore'} label="Explore" icon={<FindInPage />} />
                                <Divider orientation="vertical" flexItem variant="middle" sx={{ mt: 2, mb: 2 }} />
                                <NavLinkSimple to={'/users'} label="Users" icon={<PeopleAltIcon />} />
                                <NavLinkSimple to={'/endpoints'} label="Endpoints" icon={<ComputerIcon />} />
                                <NavLinkSimple to={'/printers'} label="Printers" icon={<PrintIcon />} />
                                <Divider orientation="vertical" flexItem variant="middle" sx={{ mt: 2, mb: 2 }} />
                                <NavLinkSimple to={'/settings'} label="Settings" icon={<SettingsIcon />} />
                            </Stack>
                        </Box>
                        <Box>
                            <IconButton aria-label="search" onClick={() => setOpenSearchBox(!openSearchBox)}>
                                <SearchIcon />
                            </IconButton>
                            {user ?
                                <Tooltip title="Account settings">
                                    <Button className={classes.loginButton} onClick={handleClick} size="small" sx={{ ml: 2 }}>
                                        <Avatar sx={{ width: 32, height: 32 }} src={user.avatar}>MŠ</Avatar>
                                        <Box sx={{ flexGrow: 1, display: { sm: 'block', xs: 'none' } }}>
                                            <Typography component="div">{user.firstname} {user.lastname}</Typography>
                                            <Typography component="div">{user.email}</Typography>
                                        </Box>
                                    </Button>
                                </Tooltip> : null}

                        </Box>
                    </Toolbar>
                </StyledAppBar>
                <Box sx={{ pb: 5 }}>
                    <Switch>
                        <Route exact path='/logs'>
                            <Logs />
                        </Route>
                        <Route exact path='/events'>
                            <Events />
                        </Route>
                        <Route exact path='/explore'>
                            <Explore />
                        </Route>
                        <Route exact path='/triggers'>
                            <Triggers />
                        </Route>
                        <Route exact path='/trigger/:id'>
                            <Trigger />
                        </Route>
                        <Route exact path='/definitions'>
                            <Definitions />
                        </Route>
                        <Route exact path='/definition'>
                            <Definition />
                        </Route>
                        <Route exact path='/definition/:id'>
                            <Definition />
                        </Route>
                        <Route exact path='/actions'>
                            <Actions />
                        </Route>
                        <Route exact path='/action'>
                            <Action />
                        </Route>
                        <Route exact path='/users'>
                            <Users />
                        </Route>
                        <Route exact path='/user'>
                            <User />
                        </Route>
                        <Route exact path='/endpoints'>
                            <Endpoints />
                        </Route>
                        <Route exact path='/action/:id'>
                            <Action />
                        </Route>
                        <Route exact path='/printers'>
                            <Printers />
                        </Route>
                        <Route exact path='/printer'>
                            <Printer />
                        </Route>
                        <Route exact path='/settings'>
                            <Settings />
                        </Route>
                    </Switch>
                </Box>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                    <MenuItem onClick={() => logout()}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
                <Drawer
                    anchor={'left'}
                    open={mobileOpen}
                    onClose={() => setMobileOpen(false)}
                    onOpen={() => { }}
                >
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={() => setMobileOpen(false)}
                    >
                        <List>
                            <ListItem button component={NavLink} to={`/dashboard`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <DashboardRounded />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button component={NavLink} to={`/events`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <NotificationsActiveRounded />
                                </ListItemIcon>
                                <ListItemText primary="Events" />
                            </ListItem>
                            <ListItem button component={NavLink} to={`/explore`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <FindInPage />
                                </ListItemIcon>
                                <ListItemText primary="Explore" />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button component={NavLink} to={`/triggers`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <FlashOn />
                                </ListItemIcon>
                                <ListItemText primary="Triggers" />
                            </ListItem>
                            <ListItem button component={NavLink} to={`/actions`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <SendRounded />
                                </ListItemIcon>
                                <ListItemText primary="Actions" />
                            </ListItem>
                            <ListItem button component={NavLink} to={`/definitions`} >
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <LibraryBooks />
                                </ListItemIcon>
                                <ListItemText primary="Definitions" />
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
                <Search open={openSearchBox} close={setOpenSearchBox} />
            </Box >
        );

    } else {
        return <Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                open={true}
            >
                <CircularProgress color="info" disableShrink />
            </Backdrop>
        </Box>
    }

}

const NavLinkSimple = ({ to, label, icon }) => {
    const classes = useStyles();

    return (
        <Button component={NavLink} to={to} className={classes.navLinkButton} style={{ borderRadius: 0 }}>
            <Stack direction="row" spacing={1} alignItems="center">
                {icon}
                <Box component="span">{label}</Box>
            </Stack>

        </Button>
    )
}

export default Main;




const StyledAppBar = styled((props) => <AppBar {...props} />)(({ theme }) => ({
    zIndex: 1200,
    backdropFilter: 'blur(10px)'
}));

const useStyles = makeStyles((theme) => ({
    navLinkButton: {
        '& svg': {
            marginBottom: 1,
            height: 18,
            color: theme.palette.text.secondary,
        },
        '& span': {
            fontSize: theme.typography.pxToRem(11),
            color: theme.palette.text.secondary,
            marginLeft: 4,
        },
        '& .MuiBox-root': {
            marginLeft: 4,
        },
        '& > div': {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(11),
        height: 55,
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'row',
        textTransform: 'uppercase',


        '&.active': {
            backgroundColor: 'white',
            borderBottomWidth: 2,
            borderBottomColor: theme.palette.primary.main,
            borderBottomStyle: 'solid',
            marginBottom: -2
        },
        '&.active span': {
            color: theme.palette.primary.main,
        },
        '&.active svg': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
        },
        '& button': {
            minWidth: 'initial',
        },
        '& button svg': {
            height: 16,
            color: theme.palette.text.secondary,
        }
    },
    groupButton: {

    },
    loginButton: {
        textAlign: 'left',
        marginRight: theme.spacing(1),
        '& .MuiAvatar-root': {
            marginRight: theme.spacing(1),
            fontSize: theme.typography.pxToRem(12),
        },
        '& .MuiTypography-root': {
            fontSize: theme.typography.pxToRem(12),
            color: '#647188',
            textTransform: 'none',
        },
        '& .MuiTypography-root:first-child': {
            fontSize: theme.typography.pxToRem(13),
            fontWeight: 500,
            color: theme.palette.text.primary,
        }
    }
}))
