import React, { useState, useEffect } from 'reactn';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { post as ApiPost } from '../lib/Api';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FilterListIcon from '@material-ui/icons/FilterList';
import { theme } from '../theme/authlify-mui-theme';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Moment from 'react-moment';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getSeverenityInfo } from '../lib/Tools';
import { Helmet } from 'react-helmet-async';

const TableComponent = React.memo(({ data }) => {
    const classes = useStyles();
    return (
        <Box>
            <Helmet>
                <title>Authlify | Triggers</title>
            </Helmet>
            <TableContainer>
                <Table aria-label="event list table" size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 180 }}>Created</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Severenity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.data && data.data.map((row) => (
                            <TableRow key={uuidv4()} >
                                <TableCell>
                                    <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                                </TableCell>
                                <TableCell >
                                    <Box  >
                                        <Link component={NavLink} to={`trigger/${row._id}`}> {row.name}</Link>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {getSeverenityInfo(row.severenity).title}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
});

export default function Triggers() {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ limit: 50, skip: 0, name: '', severenity: -1 });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePage({ page: newPage, rowsPerPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        changePage({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const changePage = async (a) => {
        setFilter({ ...filter, limit: a.rowsPerPage, skip: a.page * a.rowsPerPage })
    }

    const fetchData = async (filter) => {
        const response = await ApiPost('triggers', filter);
        response.status === 200 && setData(response.data);
    }

    useEffect(() => {
        fetchData(filter)
    }, [filter])

    const changeFilter = (event, name, value) => {
        const _name = name || event.target.name;
        const _value = value || event.target.value;
        setPage(0);
        setFilter({ ...filter, [_name]: _value })
    }
    return (
        <Box minHeight="100%" overflow="auto">
            <Helmet>
                <title>Authlify | Triger</title>
            </Helmet>
            <Grid item container alignItems="center" direction="row" className={classes.header} wrap="nowrap">
                <Grid item container direction="row" alignItems="center">
                    <FlashOnIcon style={{ marginRight: theme.spacing(1) }} />
                    <Typography variant="h6" component="h1" className={classes.h1}>Triggers <Typography color="textSecondary" variant="body2" component="span">({data.count})</Typography></Typography>

                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                    <Button component={NavLink} to={`trigger`} color="primary" size="small" startIcon={<AddBoxIcon />}>Create trigger</Button>
                </Grid>
            </Grid >
            <Grid container item justifyContent="space-between" alignItems="center" className={classes.filter}>
                <Box >
                    <Grid container item alignItems="center">
                        <FilterListIcon style={{ marginRight: theme.spacing(1) }} />
                        <Typography variant="subtitle2" component="span" style={{ marginRight: theme.spacing(2) }}>Filter</Typography>
                        <TextField
                            size="small"
                            label="Name"
                            id="name"
                            variant="outlined"
                            value={filter.eventID}
                            name="name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="All"
                            onChange={(event) => changeFilter(event)}
                            style={{ marginRight: theme.spacing(3) }}
                        />
                        <FormControl variant="outlined" className={classes.formControl} size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Severenity</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                name="severenity"
                                value={filter.severenity}
                                onChange={(event) => changeFilter(event)}
                                label="Severenity"

                            >
                                <MenuItem value={-1}>All</MenuItem>
                                <MenuItem value={1}>Information</MenuItem>
                                <MenuItem value={2}>Warning</MenuItem>
                                <MenuItem value={3}>Average</MenuItem>
                                <MenuItem value={4}>High</MenuItem>
                                <MenuItem value={5}>Critical</MenuItem>
                            </Select>
                        </FormControl>
                        <Box ml={-2}>
                            <Fade in={Boolean(filter.name || filter.severenity > -1)}>
                                <Button color="primary" size="small" onClick={() => setFilter({ limit: 50, skip: 0, name: '', severenity: -1 })} startIcon={<ClearIcon />}>Clear filter</Button>
                            </Fade>
                        </Box>
                    </Grid>
                </Box>
                <Box>
                    <Button onClick={() => fetchData(filter)} color="primary" startIcon={<RefreshIcon />} size="small" >Refresh</Button>
                </Box>
            </Grid>
            <TableComponent data={data} />
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.count || 20}
                rowsPerPage={rowsPerPage}
                page={page || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box >
    )
};

const useStyles = makeStyles((theme) => ({
    header: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    filter: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    h1: {
        fontWeight: 500,
        marginRight: 10,
    },
    table: {
        minWidth: 650,
        '& tbody tr': {
            backgroundColor: 'white'
        }
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 200,
    },
}))