import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import EventIcon from '@mui/icons-material/Event';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextField from '@mui/material/TextField';
import { fromMidnight, timeTool, getFromBySecondsBack } from '../../lib/Tools';
import TodayIcon from '@mui/icons-material/Today';

const DateRange = ({ onDateRangeSelect, label: _label, dateRange: _dateRange }) => {

    const [label, setLabel] = useState(_label);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [dateRange, setDateRange] = useState({ type: null, label: _label, from: _dateRange.from, to: _dateRange.to });
    const [defaultValue, setDefaultValue] = useState({ minutes: 15, hours: 3, days: 7 });

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onApply = () => {
        const { from, to } = dateRange;
        setAnchorEl(null);
        setLabel(dateRange.label);
        onDateRangeSelect({ from, to });
    };


    const handleMenuItemClick = ({ from, to, label, type, apply, value, select }) => {
        if (type === 'range') {
            label = `${timeTool(from).datetimeFormat()} - ${timeTool(to).datetimeFormat()}`;
        }
        setDateRange({ from, to, label, type });
        value && setDefaultValue({ ...defaultValue, [type]: value });
        apply && onApply();
    }

    return (
        <StyledBoxContainer>
            <Button startIcon={<AccessTimeIcon />} onClick={handleClickListItem} size="small" color="primary" >{label}</Button>
            <Menu
                id="daterange-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'daterange-button',
                    role: 'listbox',
                }}
            >
                <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ pl: 2, pt: 0.5, pb: 0.5 }}>
                    <Typography component="div" variant="body2" sx={{ fontWeight: 500 }}>Select time range</Typography>
                </Stack>
                <Stack direction="row" spacing={0}>
                    <Box>
                        <MenuList dense sx={{ minWidth: 300, pb: 0 }}>
                            <Divider />
                            <StyledMenuItem selected={dateRange.type === 'today'} onClick={() => handleMenuItemClick({ from: fromMidnight().getTime(), label: 'Today', type: 'today' })}>
                                <ListItemIcon sx={{ minWidth: 'auto' }}>
                                    <TodayIcon />
                                </ListItemIcon>
                                <StyledListItemText>today</StyledListItemText>
                            </StyledMenuItem>
                            <MenuItemComp value={defaultValue.minutes} label="minutes" active={dateRange.type} multiplier={60} updateFn={handleMenuItemClick} />
                            <MenuItemComp value={defaultValue.hours} label="hours" active={dateRange.type} multiplier={3600} updateFn={handleMenuItemClick} />
                            <MenuItemComp value={defaultValue.days} label="days" active={dateRange.type} multiplier={86400} updateFn={handleMenuItemClick} />
                            <StyledMenuItem selected={dateRange.type === 'range'} onClick={() => handleMenuItemClick({ ...dateRange, type: 'range' })}>
                                <ListItemIcon>
                                    <EventIcon />
                                </ListItemIcon>
                                <StyledListItemText>Enter custom range</StyledListItemText>
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                            </StyledMenuItem>
                        </MenuList>
                    </Box>
                    {dateRange.type === 'range' ? <DateRangeMemo dateRange={dateRange} updateFn={handleMenuItemClick} /> : null}
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" sx={{ pl: 2, pr: 2, pt: 1 }}>
                    <Button onClick={() => setAnchorEl(null)} size="small" variant="text" color="primary" >Cancel</Button>
                    <Button onClick={onApply} size="small" variant="contained" color="primary" >Apply</Button>
                </Stack>
            </Menu>
        </StyledBoxContainer>
    )
}




const DateRangeMemo = ({ updateFn, dateRange: _dateRange }) => {

    const [dateRange, setDateRange] = useState({
        from: timeTool(_dateRange.from).picker(),
        to: timeTool(_dateRange.to).picker()
    });

    const handleChange = (e, type) => {
        const dateTime = e.target.value;
        const newDateRange = { ...dateRange, [type]: dateTime };
        setDateRange(newDateRange);
        updateFn({ from: timeTool(newDateRange.from).second(), to: timeTool(newDateRange.to).second(), type: 'range', select: true })

    };

    return (
        <StyledDateRange>
            <Stack spacing={4} alignItems="center">
                <StyledTextFieldRange
                    id="datetime-local-from"
                    label="From"
                    size="small"
                    type="datetime-local"
                    value={dateRange.from}
                    onChange={(newValue) => { handleChange(newValue, 'from') }}
                    InputLabelProps={{ shrink: true }}
                />
                <StyledTextFieldRange
                    id="datetime-local-to"
                    label="To"
                    size="small"
                    type="datetime-local"
                    value={dateRange.to}
                    onChange={(newValue) => { handleChange(newValue, 'to') }}
                    InputLabelProps={{ shrink: true }}
                />
            </Stack>
        </StyledDateRange>
    )
}

const MenuItemComp = ({ updateFn, value: _value, multiplier, label, active }) => {
    const [value, setValue] = useState(_value);

    const onInputChange = (_value) => {
        setValue(_value);
        updateFn({ from: getFromBySecondsBack(_value * multiplier), label: `Last ${_value} ${label}`, type: label, value: _value })
    }

    const onKeyUp = (e) => {
        const { key, keyCode } = e;
        if (key === 'Enter' || keyCode === 13) {
            updateFn({ from: getFromBySecondsBack(value * multiplier), label: `Last ${value} ${label}`, type: label, value: _value, apply: true })
        }
    }

    return (
        <StyledMenuItem selected={active === label} onClick={() => updateFn({ from: getFromBySecondsBack(value * multiplier), label: `Last ${value} ${label}`, type: label })}>
            <StyledTextField onKeyUp={onKeyUp} id="outlined-basic" variant="outlined" size="small" value={value} onChange={e => onInputChange(e.target.value)} onClick={() => updateFn({ from: getFromBySecondsBack(value * multiplier), label: `Last ${value} ${label}`, type: label })} />
            <ListItemText>{label}</ListItemText>
        </StyledMenuItem>
    )
}


export default DateRange;

const StyledBoxContainer = styled((props) => <Box {...props} />)(({ theme }) => ({

}));

const StyledMenuItem = styled((props) => <MenuItem {...props} />)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 10,
    '&.Mui-selected span': {
        color: theme.palette.primary.main
    },
    '&.Mui-selected input': {
        color: theme.palette.primary.main,
    },
    '&.Mui-selected fieldset': {
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-selected svg': {
        fill: theme.palette.primary.main,
    }
}));

const StyledDateRange = styled((props) => <Box {...props} />)(({ theme }) => ({
    padding: theme.spacing(2),
    minWidth: 300,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderLeftWidth: 'thin',
    marginTop: 8
}));

const StyledListItemText = styled((props) => <ListItemText {...props} />)(({ theme }) => ({
    paddingLeft: 20,
    '&.MuiListItemText-inset': {
        paddingLeft: 55,
    }
}));

const StyledTextFieldRange = styled((props) => <TextField {...props} />)(({ theme }) => ({
    '& input': {
        fontSize: theme.typography.pxToRem(14),
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 11,
        paddingBottom: 9
    }

}));

const StyledTextField = styled((props) => <TextField {...props} />)(({ theme }) => ({
    width: 40,
    padding: 0,
    marginRight: 16,
    '& input': {
        fontSize: theme.typography.pxToRem(14),
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 3,
        paddingBottom: 3,
        textAlign: 'center'
    }

}));
