import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { HelmetComponent } from '../lib/Components';
import BasicBarChart from './components/BasicBarChart';
import BasicTable from './components/BasicTable';
import PrintIcon from '@mui/icons-material/Print';
import SimpleInfo from './components/SimpleInfo';
import BarChartIcon from '@mui/icons-material/BarChart';
import DateRange from './components/DateRange';
import { fromMidnight, useInterval } from '../lib/Tools';

const Printers = () => {
    const dateRangeLabel = `Today`;
    const [dateRange, setDateRange] = useState({ from: fromMidnight().getTime() });

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    const onBarMouseClick = (timestamp, diff) => {
        setDateRange({ from: timestamp, to: timestamp, diff });
    }

    useInterval(() => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }, 60000);

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>

                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>

                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <PrintIcon style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Printers</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <DateRange label={dateRangeLabel} dateRange={dateRange} onDateRangeSelect={(data) => setDateRange(data)} />
                                <Button onClick={() => filter()} startIcon={<Refresh />} size="small" color="primary"  >Refresh</Button>
                            </Stack>
                        </Stack>
                        <Grid container spacing={3} sx={{}}>
                            <Grid item xs={12} md={9}>
                                <BasicBarChart onBarMouseClick={onBarMouseClick} config={{ url: 'data/data', dateRange, name: 'Pages printed', type: 'pagesPrinted' }} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SimpleInfo config={{ url: 'data/data', dateRange, name: '', type: 'totalPagesPrinted' }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
                            <Grid item xs={12} md={3}>
                                <BasicTable icon={<BarChartIcon />} config={{ url: 'data/data', dateRange, name: 'TOP 10 Printer', type: 'printedPagesByPrinter', limit: 10, column: ['auto', '55px', '100px'] }} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <BasicTable icon={<BarChartIcon />} config={{ url: 'data/data', dateRange, name: 'TOP 10 User', type: 'printedPagesByUser', limit: 10, column: ['auto', '55px', '100px'] }} />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <BasicTable icon={<BarChartIcon />} config={{ url: 'data/data', dateRange, name: 'TOP 10 User/printer', type: 'printedPagesUserPrinter', limit: 10, column: ['auto', 'auto', '55px', '100px'] }} />
                            </Grid>
                            <Grid item xs={12} md={6}>

                            </Grid>
                            <Grid item xs={12} md={6}>

                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={12}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'All Printers', type: 'allPrinters', column: ['auto', '80px', '15%'] }} />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}

export default React.memo(Printers);