import React, { useEffect, useState } from 'reactn';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography, Button, Link, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/authlify-mui-theme';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { post as ApiPost } from '../lib/Api';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const TableComponent = React.memo(({ data, defaultData, event, updateDataLocalized, removeDataLocalized }) => {
    const classes = useStyles();
    return (
        <Box>
            <TableContainer>
                <Table aria-label="event list table" size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 60 }}>Key</TableCell>
                            <TableCell>Value (last event)</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell style={{ width: 20 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && Object.entries(data).map(([key, value], index) => (
                            <TableRow key={index} >
                                <TableCell>
                                    {index}
                                </TableCell>
                                <TableCell>
                                    {event && event.data[`d${index}`]}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        id={`name-${index}`}
                                        variant="outlined"
                                        name="name"
                                        value={value}
                                        onChange={(_event) => { updateDataLocalized(_event, index) }}
                                    />
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0 }}>
                                    {(index + 1 === Object.keys(data).length && (index + 1 > (event ? Object.keys(event.data).length : 0))) ?
                                        <IconButton onClick={() => removeDataLocalized(event, index)} color="primary" aria-label="upload picture" size="small" component="span" style={{ marginLeft: theme.spacing(1) }}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton> : null}
                                </TableCell>
                            </TableRow>
                        ))}
                        {!data || (data && Object.entries(data).length === 0) ? <TableRow>
                            <TableCell colSpan={4}>
                                <Box m={1} textAlign="center" fontStyle="italic">
                                    <Typography variant="body2" component="div">no data</Typography>
                                </Box>
                            </TableCell>
                        </TableRow> : null}
                    </TableBody>
                </Table>

            </TableContainer>

        </Box>
    )
});

export default function Definition() {
    const classes = useStyles();
    const { id, accountID } = useParams();
    const [data, setData] = useState();
    const [goTo, setGoTo] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [language, setLanguage] = useState('en');
    const [showLanguageDialog, setShowLanguageDialog] = useState(false);
    const [addLanguage, setAddLanguage] = useState('');
    const [requireError, setRequireError] = useState();


    useEffect(() => {
        const fetchData = async () => {
            const response = await ApiPost(`definition`, { id });
            if (response.status === 200) {
                let _data = response.data;
                let dataEventCount = _data.event ? Object.entries(_data.event.data).length : 0;

                for (let v = 0; v < dataEventCount; v++) {
                    for (const [key,] of Object.entries(_data.localized)) {
                        if (!_data.localized[key].data[`d${v}`]) {
                            _data.localized[key].data[`d${v}`] = ''
                        }
                    }
                }
                setData(_data);
            }
        }
        if (id) {
            fetchData();
        } else {
            setData({ event_id: null, event_type: '', localized: { en: { name: '', description: '', data: {} } } });
        }

    }, [id])

    const saveForm = async () => {
        let error = [];
        setRequireError(null);
        if (!data.event_id) { error.push('event_id') };
        if (!data.event_type) { error.push('event_type') };
        for (const [key,] of Object.entries(data.localized)) {
            if (!data.localized[key].name) error.push(`${key} - 'name'`);
        }

        if (error.length > 0) {
            console.log(error);
            setRequireError(error);
            return;
        }
        const response = await ApiPost(`definition/save`, data);
        if (response.status === 200) {
            setGoTo(`/${accountID}/definitions`)
        }
    }

    const deleteDefinition = async () => {
        const response = await ApiPost(`definition/delete`, data);
        if (response.status === 200) {
            setGoTo(`/${accountID}/definitions`)
        }
    }

    const updateForm = (event) => {
        let { name, value, type } = event.target;
        value = type === 'number' ? parseInt(value) : value;
        setData({ ...data, [name]: value })
    }

    const updateLocalized = (event, index) => {
        const { name, value } = event.target;
        let localized = data.localized;
        localized[language][name] = value;
        setData({ ...data, localized });
    }
    const updateDataLocalized = (event, index) => {
        const { value } = event.target;
        let localized = data.localized;
        localized[language].data[`d${index}`] = value;
        setData({ ...data, localized });

    }



    const addLocalization = (lang) => {
        lang = String(lang).toLocaleLowerCase();
        let localized = data.localized;
        data.localized[lang] = {
            name: '',
            description: '',
            data: {}
        }

        for (const [key,] of Object.entries(data.localized['en'].data)) {
            data.localized[lang].data[key] = ''
        }
        setData({ ...data, localized });
        setLanguage(lang);
        setShowLanguageDialog(false);
        setAddLanguage('')
    }

    const removeLocalization = (lang) => {
        let localized = data.localized;
        delete data.localized[lang];
        setData({ ...data, localized });
        setLanguage('en');
    }

    const removeDataLocalized = (event, index) => {
        let localized = data.localized;
        for (const [key,] of Object.entries(data.localized)) {
            delete localized[key].data[`d${index}`];
        }

        setData({ ...data, localized });
    }

    const addDataLocalized = () => {
        let localized = data.localized;
        for (const [key,] of Object.entries(data.localized)) {
            if (!localized[key].data) {
                localized[key].data = {}
            }
            const count = Object.entries(localized[key].data).length;
            localized[key].data[`d${count}`] = ''
        }
        setData({ ...data, localized });
    }

    if (goTo) {
        return <Redirect to={goTo} />
    }


    return (
        <Box minHeight="100%" overflow="auto">
            <Helmet>
                <title>Authlify | Definitions</title>
            </Helmet>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={Boolean(requireError)} autoHideDuration={6000} onClose={() => setRequireError(null)}>
                {requireError ?
                    <Alert severity="warning" onClose={() => setRequireError(null)}>
                        {requireError.map((error, index) => <p key={index}>{error}</p>)}
                    </Alert> : null}
            </Snackbar>
            <Dialog open={showLanguageDialog} onClose={() => setShowLanguageDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Language</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter ISO 639-1 language code. (example: de)
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="language_code"
                        label="Language code"
                        fullWidth
                        value={addLanguage}
                        onChange={(event) => setAddLanguage(event.target.value)}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowLanguageDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => addLocalization(addLanguage)} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this definition?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Do you really want to remove Event ID <strong>{data && data.event_id}</strong> ({data && data.event_type})?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} variant="outlined" color="primary" size="small">
                        No
                    </Button>
                    <Button onClick={() => deleteDefinition()} variant="contained" color="secondary" size="small">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item container alignItems="center" direction="row" className={classes.header} wrap="nowrap">
                <Grid item container direction="row" alignItems="center">
                    <Link component={NavLink} to={`/${accountID}/definitions`}>
                        <ArrowBackIcon style={{ marginRight: theme.spacing(1) }} />
                    </Link>

                    <Typography variant="h6" component="h1">Edit Definition</Typography>

                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                    <Button startIcon={<SaveIcon />} variant="contained" color="primary" size="small" onClick={saveForm} style={{ marginRight: theme.spacing(1) }}>SAVE</Button>
                    <Button component={NavLink} to={`/${accountID}/definitions`} variant="outlined" size="small">CANCEL</Button>
                    {id ? <Button startIcon={<DeleteIcon />} variant="text" size="small" color="primary" onClick={() => setOpenDelete(true)} style={{ marginLeft: theme.spacing(3) }}>DELETE</Button> : null}
                </Grid>
            </Grid>

            {data ?

                <Paper className={classes.paper} square={true} elevation={0}>
                    <Grid container>
                        <Grid item xs>
                            <Box maxWidth={800}>
                                <Typography variant="h6" component="h2" className={classes.h2}>Definition</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}><TextField
                                        size="small"
                                        label="ID"
                                        required
                                        id="event_id"
                                        variant="outlined"
                                        type="number"
                                        style={{ width: '100%', maxWidth: 500 }}
                                        name="event_id"
                                        value={data.event_id}
                                        error={!data.event_id}
                                        helperText={!data.event_id && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Required</span>}
                                        onChange={(event) => { updateForm(event) }} /></Grid>
                                    <Grid item xs={10}> <TextField
                                        size="small"
                                        label="Type"
                                        required
                                        id="event_type"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        name="event_type"
                                        value={data.event_type}
                                        error={!data.event_type}
                                        helperText={!data.event_type && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Required</span>}
                                        onChange={(event) => { updateForm(event) }} /></Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                                <Box mt={2} mb={2}>
                                    <Typography variant="h6" component="h2" className={classes.h2}>Localization</Typography>
                                    <Box mb={3}>

                                        <Grid item container direction="row" alignItems="center">
                                            <FormControl variant="outlined" style={{ minWidth: 100, marginRight: theme.spacing(1) }} size="small">
                                                <InputLabel id="severenity-select-label">Language</InputLabel>
                                                <Select
                                                    labelId="severenity-select-label"
                                                    id="severenity-select"
                                                    name="severenity"
                                                    value={language}
                                                    label="Language"
                                                    onChange={(event) => { setLanguage(event.target.value) }}
                                                >
                                                    {Object.entries(data.localized).map(([key, value], index) => <MenuItem key={index} value={key}>{key.toUpperCase()}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                            <Button size="small" color="primary" startIcon={<AddIcon />} onClick={() => setShowLanguageDialog(true)}>Add</Button>
                                            {language !== 'en' ? <Button size="small" color="primary" startIcon={<DeleteOutlineIcon />} onClick={() => removeLocalization(language)}>Remove</Button> : null}
                                        </Grid>
                                    </Box>

                                    <Box mb={3}>
                                        <TextField
                                            size="small"
                                            label="Name"
                                            required
                                            error={!data.localized[language].name}
                                            helperText={!data.localized[language].name && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Required</span>}
                                            id="name"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            name="name"
                                            value={data.localized[language].name}
                                            onChange={(event) => { updateLocalized(event) }}
                                        />
                                    </Box>
                                    <Box mb={3}>
                                        <TextField
                                            size="small"
                                            label="Description"
                                            id="description"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            name="description"
                                            value={data.localized[language].description}
                                            onChange={(event) => { updateLocalized(event) }}
                                        />
                                    </Box>

                                    <Paper elevation={0} className={classes.paperSimple}>
                                        <Box m={1} ml={2} mr={2}>
                                            <Grid item container direction="row" alignItems="center" justifyContent="space-between">
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Data</Typography>
                                                <Button startIcon={<AddIcon />} color="primary" size="small" onClick={() => addDataLocalized()}>Add</Button>
                                            </Grid>
                                        </Box>
                                        <TableComponent data={data.localized[language].data} defaultData={data.localized['en'].data} event={data.event} updateDataLocalized={updateDataLocalized} removeDataLocalized={removeDataLocalized} />
                                    </Paper>


                                </Box>
                            </Box>
                        </Grid>
                        <Grid item ></Grid>
                    </Grid>



                </Paper> : null}
        </Box>
    )
};


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        borderBottom: '1px solid #e0e0e0'
    },
    paperSimple: {
        border: '1px solid #e0e0e0',
        paddingBottom: theme.spacing(2),
        '& h3': {
            fontWeight: 'bold',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        }
    },
    header: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    filter: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    h2: {
        fontWeight: 700,
        marginRight: 10,
        fontSize: 17,
        paddingBottom: theme.spacing(2)
    },
    table: {

        '& tbody tr': {
            backgroundColor: 'white'
        },
        "& tr:last-child td": {
            borderBottom: 0,
        },
        '& tbody td': {
            paddingTop: 3,
            paddingBottom: 3
        },
        '& input': {
            padding: 5,
            paddingTop: 5,
            paddingBottom: 5
        },
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 200,
    },
}))