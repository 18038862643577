import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Main from './pages/Main';
import { theme } from "./theme/authlify-mui-theme";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <Helmet>
          <title>Authlify | Login</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
