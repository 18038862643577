import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { HelmetComponent } from '../lib/Components';
import { useQuery } from '../lib/Tools';
import BasicBarChart from './components/BasicBarChart';
import moment from 'moment';
import BasicTable from './components/BasicTable';
import PrintIcon from '@mui/icons-material/Print';
//import { Divider } from '@material-ui/core';
//import AdvancedTable from './components/AdvancedTable';
import SimpleInfo from './components/SimpleInfo';
import DateRange from './components/DateRange';


const Printer = () => {
    const lastDays = 7;
    const query = useQuery();
    const [dateRange, setDateRange] = useState({ from: moment().add(lastDays * -1, 'days').unix() });

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    const onBarMouseClick = (timestamp, diff) => {
        setDateRange({ from: timestamp, to: timestamp, diff });
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setDateRange({ ...dateRange, update: new Date().getTime() });
        }, 60000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [])

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>PRINTER</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <PrintIcon style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>{query.get('printer')}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DateRange dateRange={dateRange} label={`Last ${lastDays} days`} onDateRangeSelect={(data) => setDateRange(data)} />
                                <Button onClick={() => filter()} startIcon={<Refresh />} size="small" color="primary"  >Refresh</Button>
                            </Stack>
                        </Stack>

                        <Grid container spacing={3} sx={{}}>
                            <Grid item xs={12} md={9}>
                                <BasicBarChart onBarMouseClick={onBarMouseClick} config={{ url: 'data/data', dateRange, name: 'Pages printed', type: 'pagesPrinted', printer: query.get('printer') }} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SimpleInfo config={{ url: 'data/data', dateRange, name: '', type: 'totalPagesPrinted', printer: query.get('printer') }} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
                            <Grid item xs={12}>
                                <BasicTable config={{ url: 'data/data', dateRange, type: 'printedPagesByUser', name: "User printed", printer: query.get('printer'), column: ['auto', '80px', '120px'] }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BasicTable config={{ url: 'data/data', dateRange, type: 'printedDocuments', name: "Printed documents", printer: query.get('printer'), column: ['170px', 'auto', 'auto', '80px'] }} />

                            </Grid>
                        </Grid>

                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}

export default React.memo(Printer);