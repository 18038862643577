import React, { } from 'react';
import { theme } from '../../theme/authlify-mui-theme';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';

const SavedTable = ({ data, runSaved, deleteSaved, editSaved }) => {
    return (
        <React.Fragment>
            <StyledSavedTable sx={{ display: { lg: 'block', xs: 'none' } }}>
                <Table>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ width: 70, pr: 0 }}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => runSaved(row)} style={{ marginRight: theme.spacing(2) }} startIcon={<PlayArrowIcon />}>RUN</Button>
                                </TableCell>
                                <TableCell>
                                    <Button size="small" variant="text" color="primary" onClick={() => editSaved(row)} sx={{ textTransform: 'initial' }}>
                                        {row.name}
                                    </Button>
                                    <Typography variant="subtitle2" sx={{ marginLeft: 0.5 }}>{row.description}</Typography>
                                </TableCell>
                                <TableCell sx={{ width: 80 }}>
                                    <Button size="small" variant="text" color="primary" onClick={() => deleteSaved(row)} style={{ marginRight: theme.spacing(2) }} startIcon={<DeleteIcon />}>DELETE</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledSavedTable>
            <StyledSavedTableMobile sx={{ display: { lg: 'none', xs: 'block' } }}>
                <Table>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ width: 70, pr: 0 }}>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                        <Button size="small" variant="outlined" color="primary" onClick={() => runSaved(row)} style={{ marginRight: theme.spacing(2) }} startIcon={<PlayArrowIcon />}>RUN</Button>
                                        <Button size="small" variant="text" color="primary" onClick={() => deleteSaved(row)} style={{ marginRight: theme.spacing(2) }} startIcon={<DeleteIcon />}>DELETE</Button>
                                    </Stack>
                                    <Stack direction="column" spacing={0} sx={{ mt: 1 }} alignItems="flex-start">
                                        <Button size="small" variant="text" color="primary" onClick={() => editSaved(row)} sx={{ textTransform: 'initial' }}>
                                            {row.name}
                                        </Button>
                                        <Typography variant="subtitle2" sx={{ ml: 0.5, fontSize: theme.typography.pxToRem(12) }}>{row.description}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledSavedTableMobile>
        </React.Fragment>
    )
}



const StyledSavedTable = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    '& tbody tr td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td:nth-child(2) *': {
        fontSize: theme.typography.pxToRem(12),
    },
    '& tbody tr td:nth-child(1) button': {
        fontSize: theme.typography.pxToRem(11),
    },
    '& tbody tr td:nth-child(3) button': {
        fontSize: theme.typography.pxToRem(11),
    }
}));

const StyledSavedTableMobile = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& button': {
        fontSize: theme.typography.pxToRem(11)
    }
}));


export default SavedTable;