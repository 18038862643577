import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import sha256 from "sha256";
import axios from 'axios';
import { API_URL, getAccountID } from "../lib/Api";
import Alert from '@material-ui/lab/Alert';



export default function Login() {
    const classes = useStyles();
    const [getError, setError] = useState();
    const [goToMain, setGoToMain] = useState(false);
    const { register, handleSubmit } = useForm();

    const login = async (formData) => {
        try {
            setError(null);
            let { status } = await axios({
                method: 'post',
                url: `${API_URL}${getAccountID()}login`,
                data: { email: formData.email, password: sha256(formData.password) },
                withCredentials: true
            })
            if (status === 200) {
                setGoToMain(true);
            }
        } catch (error) {
            console.error(error.response)
            setError(`(${error.response.status}) ${error.response.statusText}`);
        }
    }

    if (goToMain) {
        return <Redirect to={`/dashboard`} />
    }


    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Grid container justifyContent="flex-start" alignContent="center" alignItems="center">
                    <svg className={classes.logo} id="Logo" xmlns="http://www.w3.org/2000/svg" width="57" height="61" viewBox="0 0 57 61">
                        <path id="Path_19" data-name="Path 19" d="M28.54-5.911,13.589,32.549.1,39.672l22.633-61H34.344l22.758,61L43.947,32.549Z" transform="translate(-0.103 21.328)" fill="#0747a6" />
                    </svg>
                    <Grid item>
                        <Typography component="h1" variant="h6" className={classes.h1}>
                            Authlify
                        </Typography>
                        <Typography component="h2" variant="body1">
                            Hortim International Group
                        </Typography>
                    </Grid>
                </Grid>
                <form className={classes.form} onSubmit={handleSubmit(login)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        {...register("email")}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        {...register("password")}
                    />
                    <ForgotRegister />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        LOGIN
                    </Button>

                </form>
            </div>
            {getError ? <Box>
                <Alert severity="error">{getError}</Alert>
            </Box> : null}
            <Box mt={4}>
                <Typography variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://authlify.com/">
                        Authlify.com
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Box>
        </Container>
    );
}

function ForgotRegister() {
    return (
        <Box mt={1}>
            <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    h1: {
        fontWeight: 900
    },
    logo: {
        width: "40px",
        marginRight: theme.spacing(2),
    }

}));
