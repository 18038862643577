import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet-async';

export const ConfirmDialog = ({ open, yes, no, title, description }) => {
    return (
        <Dialog
            open={open}
            onClose={no}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            transitionDuration={0}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={no} variant="outlined" color="primary" size="small">
                    No
                </Button>
                <Button onClick={yes} variant="contained" color="error" size="small">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const HelmetComponent = ({ title }) => {
    return (
        <Helmet>
            <title>Authlify | {title}</title>
        </Helmet>
    )
}
