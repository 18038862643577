import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { theme } from '../theme/authlify-mui-theme';
import Moment from 'react-moment';
import { formatString, formatStringLang, getSeverenityInfo, numberWithSpaces } from '../lib/Tools';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import NotificationsActiveRounded from '@mui/icons-material/NotificationsActiveRounded';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Helmet } from 'react-helmet-async';
import { post as ApiPost } from '../lib/Api';
import moment from 'moment';
import DateRange from './components/DateRange';
import { Waypoint } from 'react-waypoint'
import LinearProgress from '@mui/material/LinearProgress';

const Events = () => {
    const lastDays = 7;
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState({ limit: 30, skip: 0, severenity: -1, status: -1, eventID: '', from: moment().add(lastDays * -1, 'days').unix() });
    const [loading, setLoading] = useState(false);

    const fetchData = async (filter, refresh) => {
        setLoading(true);
        if (refresh) {
            filter = { ...filter, skip: 0 }
        }
        const response = await ApiPost(`alerts`, filter);
        if (filter.skip > 0) {
            response.status === 200 && setEvents({ ...events, data: [...events.data, ...response.data.data] });
        } else {
            response.status === 200 && setEvents(response.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchData(filter)
        // eslint-disable-next-line
    }, [filter])

    const onDateRange = ({ from, to }) => {
        setFilter({ ...filter, from, to, skip: 0 });
    }

    const onWaypointEnter = async () => {
        let { limit, skip } = filter;
        setFilter({ ...filter, skip: skip + limit });
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Helmet>
                <title>Authlify | Events</title>
            </Helmet>
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <NotificationsActiveRounded />
                                <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Events
                                    {events.count > 0 ? <Typography color="text.secondary" variant="caption" component="span" sx={{ ml: 1 }}>({numberWithSpaces(events.count)})</Typography> : null}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <DateRange dateRange={{ from: filter.from, to: filter.to }} onDateRangeSelect={(data) => onDateRange(data)} label={`Last ${lastDays} days`} />
                                <ButtonLoading onClick={() => fetchData(filter, true)} title="Refresh" loading={loading} />
                            </Stack>
                        </Stack>
                        <Box sx={{ background: theme.palette.background.paper, padding: 0, borderRadius: 2, overflow: 'hidden', borderColor: '#EFF0F2', borderWidth: 1, borderStyle: 'solid', boxShadow: '0px 2px 13px rgba(0,0,0,0.04)' }}>
                            <Grid container>
                                <Grid item xs >

                                </Grid>
                                <Grid item>

                                </Grid>
                            </Grid>
                            <Box mt={1} sx={{ position: 'relative' }}>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                                    open={false}
                                >
                                    <CircularProgress color="info" />
                                </Backdrop>
                                <EventsTable events={events} onWaypointEnter={onWaypointEnter} loading={loading} />
                            </Box>
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
            </Grid>
        </Box >
    )
}



const ButtonLoading = ({ disabled, title, loading, onClick }) => {
    return (
        <StyledButtonLoading disabled={loading || disabled} startIcon={
            <React.Fragment>
                {loading ? <CircularProgress size={16} sx={{ mt: 0 }} /> : <Refresh />}
            </React.Fragment>
        } size="small" color="primary" style={{ height: 28 }} onClick={onClick}>{title}</StyledButtonLoading>
    )
}
const StyledButtonLoading = styled((props) => <Button {...props} />)(({ theme }) => ({
    '& .MuiButton-startIcon': { width: 20 }
}));


const EventsTable = React.memo(({ events, changeFilter, onWaypointEnter, loading }) => {
    return (
        <Box>
            <StyledTable sx={{ display: { lg: 'block', xs: 'none' } }}>
                <Table aria-label="events table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell sx={{ minWidth: 180 }}>TIMESTAMP</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>HOST (AGENT)</TableCell>
                            <TableCell>SUMMARY</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>TRIGGER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.data && events.data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ pr: 0 }}>
                                    {getSeverenityInfo(row.trigger.severenity).icon}
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                                    <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                                    <Typography component="div">{moment(row.created).fromNow()}</Typography>
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                                    {row.event.hostname}
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Typography component="div" sx={{ fontWeight: 500, marginBottom: 0.5 }}>{formatStringLang(row.event.definition.localized, 'name', 'cs', row.event.data) || `Event ID: ${row.event.event_id}`}</Typography>
                                    </Stack>
                                    <Typography component="div" sx={{ fontFamily: 'Roboto Mono', wordBreak: 'break-word' }}>{formatString(formatStringLang(row.event.definition.localized, 'description', 'cs', row.event.data)) || `${JSON.stringify(row.event.data)}`}</Typography>
                                </TableCell>
                                <TableCell sx={{ fontFamily: 'Roboto Mono' }}>
                                    {row.trigger.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {loading ? <LinearProgress /> : null}
            </StyledTable>
            <StyledTable sx={{ display: { lg: 'none', xs: 'block' } }}>
                <Table aria-label="events table" size="small">
                    <TableBody>
                        {events.data && events.data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell >
                                    <Stack direction="row" spacing={1} justifyContent="space-between">
                                        <Stack direction="row" spacing={1} sx={{ fontSize: theme.typography.pxToRem(12), mb: 1 }} alignItems="center">
                                            {getSeverenityInfo(row.trigger.severenity).icon}
                                            <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                                        </Stack>
                                        <Typography component="div" sx={{ fontSize: theme.typography.pxToRem(12), fontFamily: 'Roboto Mono' }}>{moment(row.created).fromNow()}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>HOST:</Typography>
                                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>{row.event.hostname}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>TRIGGER:</Typography>
                                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12) }}>{row.trigger.name}</Typography>
                                    </Stack>
                                    <Stack spacing={1} sx={{ marginTop: 1 }}>
                                        <Typography component="div" sx={{ fontWeight: 500, fontSize: theme.typography.pxToRem(12) }}>{formatStringLang(row.event.definition.localized, 'name', 'cs', row.event.data) || `Event ID: ${row.event.event_id}`}</Typography>
                                        <Typography component="div" sx={{ fontFamily: 'Roboto Mono', wordBreak: 'break-all', fontSize: theme.typography.pxToRem(12) }}>{formatString(formatStringLang(row.event.definition.localized, 'description', 'cs', row.event.data)) || `${JSON.stringify(row.event.data)}`}</Typography>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {loading ? <LinearProgress /> : null}
            </StyledTable>
            {events && events.data && events.data.length > 0 ? <Waypoint onEnter={() => onWaypointEnter()} bottomOffset={-200} /> : null}

        </Box >
    )
});

export default React.memo(Events);

const StyledTable = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188'
    },
    '& tbody tr': {
        verticalAlign: 'top',

    },
    '& tbody tr td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td:not(:first-child),& tbody tr td:not(:first-child) *': {
        fontSize: theme.typography.pxToRem(12),
    }
}));

