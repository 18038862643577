import React, { useState } from 'react';
import { theme } from '../../theme/authlify-mui-theme';
import Button from '@mui/material/Button';
import { post as ApiPost } from '../../lib/Api';
import { accepts } from 'mongodb-language-model'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Trigger from '../Trigger';

const TriggerDialog = ({ open, select, cancel, query }) => {
    const [data, setData] = useState();

    const dataChange = (data) => {
        setData(data);
    }

    const save = async () => {
        if (checkForm()) {
            data.filter = JSON.parse(data.filter);
            const response = await ApiPost(`trigger/save`, data);
            if (response.status === 200) {

            }
            cancel();
        }
    }

    const checkForm = () => {
        if (!data.name) return false;
        if (!accepts(data.filter)) return false;
        return true;
    }


    return (
        <Dialog
            open={open}
            onClose={cancel}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth={'md'}
            transitionDuration={0}
        >
            <DialogTitle id="scroll-dialog-title">Create logs-based alert policy</DialogTitle>
            <DialogContent dividers={true} style={{ padding: theme.spacing(1) }}>
                <Trigger modal={true} query={query} onDataChange={(data) => dataChange(data)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => save()} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    )
};

export default TriggerDialog;