import React, { } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
const ButtonLoading = ({ disabled, title, loading, onClick }) => {
    return (
        <StyledButtonLoading disabled={loading || disabled} startIcon={
            <React.Fragment>
                {loading ? <CircularProgress size={16} sx={{ mt: 0 }} /> : <Refresh />}
            </React.Fragment>
        } size="small" color="primary" style={{ height: 28 }} onClick={onClick}>{title}</StyledButtonLoading>
    )
}
const StyledButtonLoading = styled((props) => <Button {...props} />)(({ theme }) => ({
    '& .MuiButton-startIcon': { width: 20 }
}));


export default ButtonLoading;