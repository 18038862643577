import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { accepts } from 'mongodb-language-model'
import Save from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InfoIcon from '@mui/icons-material/Info';

const EditQuery = ({ data, handleClose, handleSave }) => {
    const [_data, setData] = useState(data || { name: '', description: '', query: '' });

    const changeForm = (event) => {
        const { name, value } = event.target;
        setData({ ..._data, [name]: value });
    }

    useEffect(() => {
        setData(data || { name: '', description: '', query: '' });
    }, [data])

    return (
        <Dialog open={Boolean(data)} onClose={handleClose} fullWidth={true} maxWidth={'sm'} transitionDuration={0}>
            <DialogTitle id="form-dialog-save-query-title">Save Query</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} size="small" autoFocus margin="dense" variant="outlined" id="save-query-name" label="Name" name="name" value={_data && _data.name} fullWidth onChange={(event) => changeForm(event)}
                    />
                </Box>
                <Box mb={3}>
                    <TextField InputLabelProps={{
                        shrink: true,
                    }} size="small" margin="dense" variant="outlined" id="save-query-description" label="Description" name="description" fullWidth multilinevalue={_data && _data.description} rows={3} onChange={(event) => changeForm(event)}
                    />
                </Box>
                <Box>
                    <TextField id="save-query-query" variant="outlined" style={{ lineHeight: '1.2rem' }} name="query" label="Query" multiline rows={5} fullWidth
                        value={_data && _data.query}
                        error={!accepts(_data && _data.query)} onChange={(event) => changeForm(event)}
                        InputProps={{
                            style: { fontFamily: 'Roboto Mono', fontSize: 12, fontWeight: '400', color: '#7627bb' }
                        }}
                        helperText={!accepts(_data && _data.query) && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Query filter Error</span>}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ m: 2, mt: 0 }}>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button size="small" startIcon={<Save />} color="primary" sx={{ height: 28 }} onClick={() => handleSave(_data)} variant="contained">
                    Save Query
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditQuery;