import React, { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import { post as ApiPost } from '../../lib/Api';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputBase from '@mui/material/InputBase';
import debounce from 'lodash.debounce';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { NavLink, useHistory } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import PrintIcon from '@mui/icons-material/Print';
const Search = React.memo(({ open, close }) => {
    const [searchResult, setSearchResult] = useState({ users: [], endpoints: [], printers: [], activeIndex: -1, totalIndex: 0 });
    const [search, setSearch] = useState('');
    const history = useHistory();

    const searchChange = async (event) => {
        const { value } = event.target;
        setSearch(value);
        if (value) {
            const response = await ApiPost(`search`, { value });
            response.status === 200 && setSearchResult(response.data);
        } else {
            setSearchResult({ users: [], endpoints: [], printers: [], activeIndex: -1, totalIndex: 0 });
        }
    }

    const debouncedSearchChange = useMemo(() => debounce(searchChange, 200), []);

    useEffect(() => {
        return () => {
            debouncedSearchChange.cancel();
        }
    }, [debouncedSearchChange]);

    useEffect(() => {
        open && setSearchResult({ users: [], endpoints: [], printers: [] });
    }, [open]);

    const onKeyUp = (e) => {
        const { key, keyCode } = e;
        if (key === 'ArrowDown' || keyCode === 40) {
            if (searchResult.activeIndex < searchResult.totalIndex) {
                setSearchResult({ ...searchResult, activeIndex: searchResult.activeIndex + 1 });
            }
        }
        if (key === 'ArrowUp' || keyCode === 38) {
            if (searchResult.activeIndex > 0) {
                setSearchResult({ ...searchResult, activeIndex: searchResult.activeIndex - 1 });
            }
        }
        if (key === 'Enter' || keyCode === 13) {
            let findUser = searchResult.users.filter(x => x.index === searchResult.activeIndex);
            if (findUser.length > 0) {
                const active = findUser[0];
                history.push(active.link);
            } else {
                let findPrinter = searchResult.printers.filter(x => x.index === searchResult.activeIndex);
                if (findPrinter.length > 0) {
                    const active = findPrinter[0];
                    history.push(active.link);
                }
            }
        }
    }

    return (<StyledDialog scroll="paper" open={open} onClose={() => close(false)} fullWidth={true} maxWidth={'md'}>
        <StyledDialogTitle id="scroll-dialog-title">
            <Stack direction="row" spacing={2} alignItems="center">
                <SearchIcon fontSize="large" />
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search user, endpoint, printer ..."
                    id="search"
                    autoFocus
                    label="Search"
                    type="search"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(event) => debouncedSearchChange(event)}
                    onKeyUp={onKeyUp}
                />
            </Stack>
        </StyledDialogTitle>
        <StyledDialogContent>
            {searchResult.users.length > 0 ? <UsersSearchContainer data={searchResult.users} activeIndex={searchResult.activeIndex} search={search} /> : null}
            {searchResult.printers.length > 0 ? <PrinterSearchContainer data={searchResult.printers} activeIndex={searchResult.activeIndex} search={search} /> : null}

        </StyledDialogContent>
    </StyledDialog>
    )
});

const UsersSearchContainer = ({ data, search, activeIndex }) => {
    return (
        <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
            <Typography component="div" variant="overline" color="textSecondary" sx={{ fontWeight: 500, mb: 1 }}>Users</Typography>
            <Stack spacing={0}>
                {data.map((user, index) =>
                    <StackLink key={index} className={`${user.index === activeIndex && 'activeIndex'} user`} component={NavLink} to={user.link} direction="row" spacing={2} alignItems="center">
                        <Avatar sx={{ width: 40, height: 40 }} src={user.avatar} />
                        <Stack spacing={0}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <StyledTypography component="div" variant="body1">
                                    <Highlighter
                                        highlightClassName="highlightClass"
                                        searchWords={[search]}
                                        autoEscape={true}
                                        textToHighlight={user.firstname || user.lastname ? `${user.firstname} ${user.lastname}` : `${user.domain}\\${user.user}`}
                                    />
                                </StyledTypography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                {user.firstname ? <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{`${user.domain}\\${user.user}`}</Typography> : null}
                                <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{user.email}</Typography>
                                <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{user.phone}</Typography>
                            </Stack>
                        </Stack>
                    </StackLink>)}
            </Stack>
        </Box>
    )
}


const PrinterSearchContainer = ({ data, search, activeIndex }) => {
    return (
        <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }} >
            <Typography component="div" variant="overline" color="textSecondary" sx={{ fontWeight: 500, mb: 1 }}>Printers</Typography>
            <Stack spacing={0}>
                {data.map((printer, index) =>
                    <StackLink key={index} className={`${printer.index === activeIndex && 'activeIndex'} printer`} component={NavLink} to={printer.link} direction="row" spacing={2} alignItems="center">
                        <PrintIcon />
                        <Stack spacing={0}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <StyledTypography component="div" variant="body1">
                                    <Highlighter
                                        highlightClassName="highlightClass"
                                        searchWords={[search]}
                                        autoEscape={true}
                                        textToHighlight={printer.printer}
                                    />
                                </StyledTypography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>Total printed pages : {printer.total}</Typography>
                            </Stack>
                        </Stack>
                    </StackLink>)}
            </Stack>
        </Box>
    )
}

const StyledDialog = styled((props) => <Dialog {...props} />)(({ theme }) => ({
    '& .MuiDialog-container': {
        alignItems: 'flex-start',
        paddingTop: 100,
    },
    '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(32, 38, 45, 0.2)',
    },
    '& .MuiDialogTitle-root': {
        marginBottom: 0
    },
    backdropFilter: 'blur(2px)'
}));

const StyledDialogContent = styled((props) => <DialogContent {...props} />)(({ theme }) => ({
    padding: 0
}));

const StyledTypography = styled((props) => <Typography {...props} />)(({ theme }) => ({
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13)
}));

const StyledDialogTitle = styled((props) => <DialogTitle {...props} />)(({ theme }) => ({

}));

const StackLink = styled((props) => <Stack {...props} />)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.primary.main,
    },
    textDecoration: 'none',
    color: 'initial',
    padding: theme.spacing(1),
    border: '1px solid transparent',
    marginTop: 0,
    borderRadius: 8,
    '& mark': {
        backgroundColor: 'initial',
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    },
    '&.activeIndex': {
        backgroundColor: theme.palette.primary.background,
    },
    '&.printer svg': {
        fill: theme.palette.text.secondary
    },
    '&.user svg': {
        fill: theme.palette.primary.background
    },
    '&.user .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.background
    }
}));



export default Search;
