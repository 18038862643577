import { createTheme } from '@mui/material/styles';
import { red, green, orange, blue } from '@mui/material/colors';

const Colors = {
  border: {
    main: '#e0e0e0'
  },
}

export const theme = createTheme({
  palette: {
    text: {
      primary: '#172B4D',
      secondary: 'rgb(7 40 70 / 65%)'
    },
    primary: {
      main: "#0747A6",
      background: 'rgb(7 71 166 / 11%)'
    },
    background: {
      default: "#F5F5F5"
    },
    danger: {
      main: 'rgb(244, 67, 54)',
    },
    white: {
      main: '#fff',
    },
    header: {
      main: 'rgba(255,255,255,0.72)'
    },
    LogAlways: {
      main: blue[200]
    },
    success: {
      main: green[400]
    },
    info: {
      main: blue[400]
    },
    warning: {
      main: orange[400]
    },
    error: {
      main: red[400]
    },
    critical: {
      main: red[900]
    },
    border: Colors.border,
    _background: {
      main: '#F5F5F5'
    },
    _blue: {
      main: '#0747A6'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          background-color: #F5F5F5
        }
      `,
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontSize: '0.813rem',
        },
        danger: {
          color: '#fff'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {

        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '0.938rem',
          fontWeight: 700,
          borderBottom: '1px solid',
          borderColor: Colors.border.main,
          padding: 16,
          marginBottom: 16,
        }
      },
    },
  },
});


export const authlifyTheme = {
  header: 55
};
