import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import DashboardRounded from '@mui/icons-material/DashboardRounded';
import { HelmetComponent } from '../lib/Components';
import BasicBarChart from './components/BasicBarChart';
import moment from 'moment';
import BasicTable from './components/BasicTable';
import DateRange from './components/DateRange';

const Logs = () => {
    const lastDays = 7;
    const [dateRange, setDateRange] = useState({ from: moment().add(lastDays * -1, 'days').unix() });

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    const onBarMouseClick = (timestamp, diff) => {
        setDateRange({ from: timestamp, to: timestamp, diff });
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>

                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>

                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <DashboardRounded style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Logs</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <DateRange dateRange={dateRange} label={`Last ${lastDays} days`} onDateRangeSelect={(data) => setDateRange(data)} />
                                <Button onClick={() => filter()} startIcon={<Refresh />} size="small" color="primary"  >Refresh</Button>
                            </Stack>
                        </Stack>

                        <BasicBarChart onBarMouseClick={onBarMouseClick} config={{ url: 'data/data', dateRange, name: 'Log level', type: 'eventLevelTimeSeries' }} />

                        <Grid container spacing={3} sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'TOP 10 Critical', type: 'topTenEventLevel', level: 1, column: ['80px', 'auto', 'auto', 'auto', '80px', '50px'] }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'TOP 10 Error', type: 'topTenEventLevel', level: 2, column: ['80px', 'auto', 'auto', 'auto', '80px', '50px'] }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'TOP 10 Warning', type: 'topTenEventLevel', level: 3, column: ['80px', 'auto', 'auto', 'auto', '80px', '50px'] }} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'TOP 10 Information', type: 'topTenEventLevel', level: 4, column: ['80px', 'auto', 'auto', 'auto', '80px', '50px'] }} />
                            </Grid>
                            <Grid item xs={12}>
                                <BasicTable config={{ url: 'data/data', dateRange, name: 'TOP 10 Log Always', type: 'topTenEventLevel', level: 0, column: ['80px', 'auto', 'auto', 'auto', '80px', '50px'] }} />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}

export default React.memo(Logs);