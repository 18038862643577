import axios from 'axios';
import sha256 from 'sha256';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const API_HOST = process.env.NODE_ENV === 'development' ? 'http://hortim.localhost:3331' : "https://api.authlify.com:3330";
export const API_URL = API_HOST + '/app/';

export const getAccountID = () => {
    if (process.env.NODE_ENV === 'development') return '';
    const urlPath = window.location.host.split('.');
    if (urlPath.length > 0) {
        return `${urlPath[0]}/`;
    }
    console.error('no account id.');
    return null;
}

export const saveCustomCookies = (name, value) => {
    cookies.set(name, value, { path: '/' });
};
export const readCustomCookies = (name) => {
    return cookies.get(name);
}

export const post = async (_url, _data) => {
    let response = { data: null, error: null, status: null };
    try {
        let { data, status } = await axios({
            method: 'post',
            url: API_URL + getAccountID() + _url,
            data: _data,
            withCredentials: true
        })
        response.data = data;
        response.status = status;
        return response;
    } catch (error) {
        console.error(error)
        let status = error.response ? error.response.status : 500;
        response.error = error;
        response.status = status;
        return response;
    }
}

export const getSha256 = (value) => {
    return sha256('pred' + value + 'po', 'tajneheslo').toString();
};