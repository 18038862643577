import React, { useEffect, useState } from 'reactn';
import { Box, Grid, Typography, Button, Link, FormControl, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/authlify-mui-theme';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { post as ApiPost } from '../lib/Api';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { ActionIcon, formatStringLang, PrettyPrintJson } from '../lib/Tools';
import { Helmet } from 'react-helmet-async';
import { ConfirmDialog } from '../lib/Components';
import Actions from './Actions';
import PageviewIcon from '@material-ui/icons/Pageview';
import Moment from 'react-moment';
import { accepts } from 'mongodb-language-model'
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function Trigger({ modal, query, onDataChange }) {
    const classes = useStyles();
    const { id, accountID } = useParams();
    const [data, setData] = useState();
    const [goTo, setGoTo] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [openActionList, setOpenActionList] = useState(false);
    const [testLog, SetTestLog] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ApiPost(`trigger`, { id });
            let data = response.data;
            if (response.data && (typeof response.data.filter) === 'object') {
                data.filter = JSON.stringify(data.filter)
            }
            response.status === 200 && setData(data);
        }
        if (id) {
            fetchData();
        } else {
            let _filter = (modal && query) ? query : '{}';
            setData({ name: '', severenity: 1, enabled: false, email_template: 'trigger', filter: _filter, actions: [] });
        }
    }, [id, modal, query])

    useEffect(() => {
        if (onDataChange) {
            onDataChange(data)
        }
    }, [data, onDataChange])

    const checkForm = () => {
        if (!data.name) return false;
        if (!accepts(data.filter)) return false;
        return true;
    }

    const saveForm = async () => {
        if (checkForm()) {
            data.filter = JSON.parse(data.filter);
            const response = await ApiPost(`trigger/save`, data);
            if (response.status === 200) {
                setGoTo(`/${accountID}/triggers`)
            }
        }
    }

    const updateForm = (event) => {
        let { name, value, checked } = event.target;
        if (name === 'enabled') {
            value = checked;
        }
        const _data = { ...data, [name]: value };
        setData(_data)
    }

    const deleteTrigger = async () => {
        const response = await ApiPost(`trigger/delete`, data);
        if (response.status === 200) {
            setGoTo(`/${accountID}/triggers`)
        }
    }

    const removeAction = (action, index) => {
        let actions = data.actions;
        actions.splice(index, 1)
        setData({ ...data, actions })
    }

    const selectedActionItems = (items) => {
        var ids = new Set(data.actions.map(d => d._id));
        var newActions = [...data.actions, ...items.filter(d => !ids.has(d._id))];
        setData({ ...data, actions: newActions })
        setOpenActionList(false)
    }

    const testFilter = async () => {
        setLoading(true);
        const response = await ApiPost(`trigger/test`, { filter: data.filter, limit: 10 });
        setLoading(false);
        if (response.status === 200) {
            SetTestLog(response.data)
        }
    }

    if (goTo) {
        return <Redirect to={goTo} />
    }

    return (
        <Box minHeight="100%" overflow="auto">
            <Helmet>
                <title>Authlify | Trigger</title>
            </Helmet>
            {!modal ? <Grid item container alignItems="center" direction="row" className={classes.header} wrap="nowrap">
                <Grid item container direction="row" alignItems="center">
                    <Link component={NavLink} to={`/${accountID}/triggers`}>
                        <ArrowBackIcon style={{ marginRight: theme.spacing(1) }} />
                    </Link>
                    <Typography variant="h6" component="h1">Trigger</Typography>
                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                    <Button disabled={!data || !checkForm()} startIcon={<SaveIcon />} variant="contained" color="primary" size="small" onClick={saveForm} style={{ marginRight: theme.spacing(1) }}>SAVE</Button>
                    <Button component={NavLink} to={`/${accountID}/triggers`} variant="outlined" size="small">CANCEL</Button>
                    {id ? <Button startIcon={<DeleteIcon />} variant="text" size="small" color="primary" onClick={() => setOpenDelete(true)} style={{ marginLeft: theme.spacing(3) }}>DELETE</Button> : null}
                </Grid>
            </Grid> : null}
            {data ?
                <Paper className={classes.paper} square={true} elevation={0}>

                    <Box pt={0} pb={3} >
                        <Grid item container direction="row" spacing={1} alignItems="flex-start">
                            <Grid item>
                                <InfoIcon />
                            </Grid>
                            <Grid item style={{ flex: 1, paddingTop: 6 }}>
                                <Typography variant="body2" component="span">
                                    Create a logs-based event to be notified whenever a log that matches a provided filter is ingested. An incident will be created or assigned to each matching log entry.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mb={2}>
                        <Typography variant="h6" component="h3">Trigger Detail</Typography>
                        <Typography variant="body2" component="p" color="textSecondary">Provide basic information for this trigger.</Typography>
                    </Box>

                    <Grid container item spacing={2} xs={modal ? 12 : 8} style={{ marginBottom: theme.spacing(0) }}>
                        <Grid item style={{ flex: 1 }}>
                            <TextField
                                size="small"
                                label="Name"
                                required
                                margin="dense"
                                id="name"
                                variant="outlined"
                                name="name"
                                fullWidth
                                value={data.name}
                                error={!data.name}
                                helperText={!data.name && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Name is required</span>}
                                onChange={(event) => { updateForm(event) }} />
                        </Grid>
                        <Grid item>
                            <FormControl variant="outlined" size="small" className={classes.formControl} margin="dense" fullWidth>
                                <InputLabel id="demo-simple-select-outlined-label">Severenity</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="severenity"
                                    value={data.severenity}
                                    onChange={(event) => updateForm(event)}
                                    label="Severenity"
                                >
                                    <MenuItem value={1}>Low</MenuItem>
                                    <MenuItem value={2}>Medium</MenuItem>
                                    <MenuItem value={3}>High</MenuItem>
                                    <MenuItem value={4}>Critical</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item style={{ display: 'flex' }}>
                            <FormControlLabel style={{ height: 50 }}
                                control={
                                    <Switch
                                        checked={data.enabled}
                                        onChange={(event) => updateForm(event)}
                                        name="enabled"
                                        color="primary"
                                    />
                                }
                                label="Enabled"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={modal ? 12 : 8} style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }}>
                        <Typography variant="body1" component="h4" style={{ fontWeight: '500' }}>Trigger Description</Typography>
                        <Typography variant="body2" component="p" color="textSecondary">Include instructions or suggestions for solving the problem.</Typography>
                        <Typography variant="body2" component="p" color="textSecondary" style={{ marginTop: theme.spacing(1) }}>Your event policies may trigger messages that include personal data. Please use appropriate communication channels for these events and confirm that the event recipients are authorized to receive such data.</Typography>
                    </Grid>

                    <Grid container item xs={modal ? 12 : 8} style={{ marginBottom: theme.spacing(1) }}>
                        <TextField
                            margin="dense"
                            variant="outlined"
                            id="save-query-description"
                            label="optional"
                            name="description"
                            fullWidth
                            multiline
                            value={data.description}
                            rows={3}
                            onChange={(event) => updateForm(event)}
                        />
                    </Grid>

                    <Divider style={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(3) }} />


                    <Grid item xs={modal ? 12 : 8} style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }}>
                        <Typography variant="body1" component="h4" style={{ fontWeight: '500' }}>Who should be notified? <Typography variant="body2" component="span" color="textSecondary">(optional)</Typography></Typography>
                        <Typography variant="body2" component="p" color="textSecondary">When alerting policy violations occur, you will be notified via these channels.</Typography>
                    </Grid>

                    <Grid container item xs={modal ? 12 : 12}>
                        <Paper className={classes.tcPaper} elevation={0}>
                            <Box borderBottom="1px solid #e0e0e0" p={1}>
                                <Grid item container direction="row" alignItems="center" justifyContent="space-between" style={{ minHeight: 30 }}>
                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 12 }}>Actions  <Typography variant="caption" component="span">({data.actions.length})</Typography></Typography>
                                    <Button startIcon={<AddIcon />} color="primary" size="small" onClick={() => setOpenActionList(true)}>Add</Button>
                                </Grid>
                            </Box>
                            <Box>
                                <ActionTable data={data.actions} accountID={accountID} removeAction={removeAction} />
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={modal ? 12 : 8} style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(3) }}>
                        <Typography variant="body1" component="h4" style={{ fontWeight: '500' }}>Choose logs to include in the event</Typography>
                        <Typography variant="body2" component="p" color="textSecondary">Create an inclusion filter to determine which logs are included in logs routing sink.</Typography>
                    </Grid>

                    <Grid container item xs={modal ? 12 : 12}>
                        <Paper className={classes.tcPaper} elevation={0}>
                            <Box borderBottom="1px solid #e0e0e0" p={1}>
                                <Grid item container direction="row" alignItems="center" justifyContent="space-between" style={{ minHeight: 30 }}>
                                    <Typography variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 12 }}>Query</Typography>
                                    <Box>
                                        {!modal ? <Button startIcon={<PageviewIcon />} color="primary" variant="outlined" size="small" onClick={() => testFilter()}>Preview Log</Button> : null}
                                    </Box>
                                </Grid>
                            </Box>
                            <Box>
                                <TextField
                                    size="small"
                                    required
                                    id="filter"
                                    variant="standard"
                                    style={{ width: '100%', border: 0, padding: theme.spacing(1), lineHeight: '1.2rem' }}
                                    name="filter"
                                    multiline
                                    rows={5}
                                    InputProps={{
                                        style: { lineHeight: '1.2rem', fontFamily: 'Roboto Mono', fontSize: 12, fontWeight: '400', color: '#7627bb' },
                                        disableUnderline: true,
                                    }}
                                    value={data.filter}
                                    error={!accepts(data.filter)}
                                    helperText={!accepts(data.filter) && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Query filter Error</span>}
                                    onChange={(event) => { updateForm(event) }} />
                            </Box>
                            {loading ? <LinearProgress /> : null}
                            {testLog && testLog.length > 0 ? <Box style={{ borderTop: '1px solid #e0e0e0' }}>
                                <QueryResultTable data={testLog} loading={loading} />
                            </Box> : null}
                        </Paper>

                    </Grid>



                </Paper> : null
            }

            <ListDialog open={openActionList} select={(items) => selectedActionItems(items)} cancel={() => setOpenActionList(false)} />
            <ConfirmDialog open={openDelete} yes={() => deleteTrigger()} no={() => setOpenDelete(false)} title="Delete this trigger?" description={<span>Do you really want to remove trigger <strong>"{data && data.name}"</strong> ?</span>} />

        </Box >

    )
};

const ActionTable = React.memo(({ data, accountID, removeAction }) => {
    const classes = useStyles();
    return (
        <Box>
            <TableContainer>
                <Table aria-label="event list table" size="small" className={classes.tcTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" style={{ width: 250 }}>Name</TableCell>
                            <TableCell component="th">Targets</TableCell>
                            <TableCell component="th" style={{ width: 20 }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index} >
                                <TableCell>
                                    <Link component={NavLink} to={`/${accountID}/action/${row._id}`}>
                                        {row.name}
                                    </Link>

                                </TableCell>
                                <TableCell>
                                    {row.action.map((a, index) =>
                                        <ActionIcon key={index} action={a} style={{ marginRight: 10 }} />
                                    )}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 0 }}>
                                    <IconButton onClick={() => removeAction(row, index)} color="primary" aria-label="upload picture" size="small" component="span" style={{ marginLeft: theme.spacing(1) }}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {data.length === 0 ? <TableRow>
                            <TableCell colSpan={3}>
                                <Box m={1} textAlign="center" fontStyle="italic">
                                    <Typography variant="body2" component="div">no action</Typography>
                                </Box>
                            </TableCell>
                        </TableRow> : null}
                    </TableBody>
                </Table>

            </TableContainer>

        </Box>
    )
});

const QueryResultRow = React.memo(({ row }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <TableRow className={classes.queryTableRow}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Moment format="DD.MM.YYYY HH:mm:ss" style={{ fontFamily: 'Roboto Mono', fontSize: 12, fontWeight: '400', }}>{row.created}</Moment>
                </TableCell>
                <TableCell>
                    <Typography variant="body2" style={{ fontSize: 12 }}>{row.hostname}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2" style={{ fontSize: 12 }}>{row.event_id}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" style={{ lineHeight: 2, fontWeight: 'bold', fontSize: 12 }}>{row.definition ? formatStringLang(row.definition.localized, 'name', 'cs', row.data) : `Event ID: ${row.event_id}`}</Typography>
                    <Typography variant="subtitle2" style={{ fontSize: 12 }}>{row.definition ? formatStringLang(row.definition.localized, 'description', 'cs', row.data) : ``}</Typography>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box ml={3}>
                            <PrettyPrintJson data={row} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
});

const QueryResultTable = React.memo(({ data, loading }) => {
    const classes = useStyles();
    return (
        <TableContainer>
            <Table className={classes.tcTable}>
                <TableHead>
                    <TableRow>
                        <TableCell component="th" style={{ width: 40 }} />
                        <TableCell component="th" style={{ width: 200 }}>TIMESTAMP</TableCell>
                        <TableCell component="th" style={{ width: 150 }}>HOST (AGENT)</TableCell>
                        <TableCell component="th" style={{ width: 100 }}>EVENT ID</TableCell>
                        <TableCell component="th">SUMMARY</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((row, index) => (
                        <QueryResultRow key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    )
});

const ListDialog = ({ open, select, cancel }) => {


    const [selected, setSelected] = useState([]);

    return (
        <Dialog
            open={open}
            onClose={cancel}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth={'lg'}
            transitionDuration={0}
        >
            <DialogTitle id="scroll-dialog-title">Select Action</DialogTitle>
            <DialogContent dividers={true}>
                <Actions modal={true} selectedItems={items => setSelected(items)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => select(selected)} color="primary">
                    Select
                </Button>
            </DialogActions>

        </Dialog>
    )
};


const useStyles = makeStyles((theme) => ({
    tcPaper: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid #e0e0e0',
        marginBottom: theme.spacing(2)
    },
    tcTable: {
        '& thead th': {
            backgroundColor: '#f5f5f5',
            fontSize: 11,
            padding: theme.spacing(1),
            textTransform: 'uppercase'
        },
        '& tbody td': {
            padding: theme.spacing(1),
        },
        "& tr:last-child td": {
            borderBottom: 0,
        },
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
    },
    header: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 200,
    },
}))