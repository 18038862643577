import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { theme } from '../../theme/authlify-mui-theme';
import { numberWithSpaces } from '../../lib/Tools';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet-async';
import { post as ApiPost } from '../../lib/Api';
import FindInPage from '@mui/icons-material/FindInPage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Info from '@mui/icons-material/Info';
import moment from 'moment';
import { accepts } from 'mongodb-language-model'
import FlashOn from '@mui/icons-material/FlashOn';
import Save from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { ConfirmDialog } from '../../lib/Components';
import QueryTable from './QueryTable';
import RecentTable from './RecentTable';
import SavedTable from './SavedTable';
import TriggerDialog from './TriggerDialog';
import EditQuery from './EditQuery';
import { useLocation } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import DateRange from '../components/DateRange';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Explore = () => {
    const lastDays = 7;
    let query = useQuery();
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState({ filter: query.get("filter") ? query.get("filter") : "{}", limit: 30, skip: 0, from: moment().add(lastDays * -1, 'days').unix(), to: moment().add(1, 'months').unix() });
    const [tabIndex, setTabIndex] = useState(0);
    const [result, setResut] = useState({ data: [], count: 0, recent: [], saved: [], stats: { count: 0 } });
    const [editQuery, setEditQuery] = useState(null);
    const [openTriggerEdit, setOpenTriggerEdit] = useState(false);
    const [openDeleteSavedQuery, setOpenDeleteSavedQuery] = useState(false);

    useEffect(() => {
        const _runQuery = async () => {
            setLoading(true);
            const response = await ApiPost(`explore/query`, filter);
            if (filter.skip > 0) {
                response.status === 200 && setResut({ ...result, data: [...result.data, ...response.data.data] });
            } else {
                response.status === 200 && setResut(response.data);
            }
            setLoading(false);
        }
        accepts(filter.filter) && _runQuery();
        // eslint-disable-next-line
    }, [filter.limit, filter.skip, filter.from, filter.to])

    const runQuery = async (_filter) => {
        setLoading(true);
        _filter = _filter || filter;
        _filter = { ..._filter, from: filter.from, to: filter.to, skip: 0 };
        const response = await ApiPost(`explore/query`, _filter);
        response.status === 200 && setResut(response.data);
        setLoading(false);
    }

    const changeFilter = (event) => {
        const { value } = event.target;
        setFilter({ ...filter, filter: value })
    };

    const saveQuery = async (data) => {
        const response = await ApiPost(`explore/query/save`, { ...data, query: JSON.parse(data.query) });
        if (response.status === 200) {
            setResut({ ...result, saved: response.data })
        }
        setEditQuery(null)
    }

    const run = (recent) => {
        setFilter({ ...filter, filter: recent.filter });
        setTabIndex(0);
        runQuery({ ...filter, filter: recent.filter });
    }

    const deleteSavedQuery = async (data) => {
        const response = await ApiPost(`explore/query/delete`, data);
        if (response.status === 200) {
            setResut({ ...result, saved: response.data })
        }
        setOpenDeleteSavedQuery(null);
    }

    const onDateRange = ({ from, to }) => {
        setFilter({ ...filter, from, to, skip: 0 });
    }

    const onWaypointEnter = () => {
        let { limit, skip } = filter;
        setFilter({ ...filter, skip: skip + limit });
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Helmet>
                <title>Authlify | Explore</title>
            </Helmet>
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <FindInPage />
                                <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Log Explore
                                    {result.stats.count > 0 ? <Typography color="text.secondary" variant="caption" component="span" sx={{ ml: 1 }}>({numberWithSpaces(result.stats.count)})</Typography> : null}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <DateRange dateRange={{ from: filter.from, to: filter.to }} onDateRangeSelect={(data) => onDateRange(data)} label={`Last ${lastDays} days`} />
                            </Stack>
                        </Stack>
                        <Box sx={{ background: theme.palette.background.paper, padding: 0, borderRadius: 2, overflow: 'hidden', borderColor: '#EFF0F2', borderWidth: 1, borderStyle: 'solid', boxShadow: '0px 2px 13px rgba(0,0,0,0.04)' }}>
                            <Box mt={0} sx={{ position: 'relative' }}>
                                <Stack direction="row" justifyContent="space-between" sx={{ borderBottom: '1px solid #e0e0e0' }} >
                                    <StyledTabs
                                        value={tabIndex}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        onChange={(event, newValue) => setTabIndex(newValue)}
                                        aria-label="disabled tabs example">
                                        <StyledTab label="Query" />
                                        <StyledTab label={
                                            <Box component="span">Recent <Typography variant="caption" component="span">({numberWithSpaces(result.recent.length)})</Typography></Box>
                                        } />
                                        <StyledTab label={
                                            <Box component="span">Saved <Typography variant="caption" component="span">({numberWithSpaces(result.saved.length)})</Typography></Box>
                                        } />
                                    </StyledTabs>
                                    {tabIndex === 0 ? <Stack direction="row" spacing={2} alignItems="center" sx={{ mr: 2, display: { md: 'flex', xs: 'none' } }} >

                                        <Button disabled={!accepts(filter.filter) || filter.filter === '{}'} startIcon={<Save />} size="small" color="primary" style={{ height: 28, marginRight: theme.spacing(2) }} onClick={() => setEditQuery({ name: '', description: '', query: filter.filter })}>Save</Button>
                                        <ButtonLoading disabled={!accepts(filter.filter) || filter.filter === '{}'} startIcon={
                                            <React.Fragment>
                                                {loading ? <CircularProgress size={16} sx={{ mt: 0 }} color="white" /> : <PlayArrowIcon />}
                                            </React.Fragment>
                                        } variant="contained" size="small" color="primary" style={{ height: 28 }} onClick={() => runQuery()}>Run Query</ButtonLoading>
                                    </Stack> : null}
                                </Stack>
                                <TabPanel value={tabIndex} index={0}>
                                    <TextField
                                        size="small"
                                        required
                                        id="filter"
                                        variant="standard"
                                        style={{ width: '100%', border: 0, padding: theme.spacing(1), lineHeight: '1.2rem' }}
                                        name="filter"
                                        multiline
                                        rows={5}
                                        value={filter.filter}
                                        error={!accepts(filter.filter)}
                                        InputProps={{
                                            style: { fontFamily: 'Roboto Mono', fontSize: 12, fontWeight: '400', color: '#7627bb' },
                                            disableUnderline: true,
                                        }}
                                        helperText={!accepts(filter.filter) && <span><Info fontSize="small" style={{ marginRight: 5, float: 'left' }} />Query filter Error</span>}
                                        onChange={(event) => { changeFilter(event) }} />
                                    <StyledFab sx={{ display: { md: 'none', xs: 'flex' } }} size="small" color="primary" aria-label="run query" onClick={() => runQuery()} disabled={!accepts(filter.filter) || filter.filter === '{}'} >
                                        {loading ? <CircularProgress size={16} sx={{ mt: 0 }} color="white" /> : <PlayArrowIcon />}
                                    </StyledFab>
                                </TabPanel>
                                <TabPanel value={tabIndex} index={1}>
                                    <RecentTable data={result.recent} run={(recent) => run(recent)} />
                                </TabPanel>
                                <TabPanel value={tabIndex} index={2}>
                                    <SavedTable data={result.saved} runSaved={(saved) => run({ filter: saved.query })} deleteSaved={(deleted) => setOpenDeleteSavedQuery(deleted)} editSaved={(edit) => setEditQuery(edit)} />
                                </TabPanel>
                            </Box>
                            <Box sx={{ display: tabIndex === 0 ? 'block' : 'none', position: 'relative' }}>
                                <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid #e0e0e0', pl: 2, pt: 1, pr: 2 }} >
                                    <TableTitleSmall component="div">Query result
                                        <Typography sx={{ ml: 1 }} variant="caption" component="span" color="text.secondary">({result.count > 1000 ? '> 1000' : numberWithSpaces(result.count)})</Typography>
                                    </TableTitleSmall>
                                    <Button sx={{ display: { md: 'flex', xs: 'none' } }} startIcon={<FlashOn />} disabled={result.count === 0} color="primary" variant="text" size="small" onClick={() => setOpenTriggerEdit(true)}>Create Trigger</Button>
                                </Stack>
                                <QueryTable data={result.data} loading={loading} onWaypointEnter={onWaypointEnter} />
                            </Box>
                        </Box>
                    </Container>
                </Grid>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
            </Grid>
            <EditQuery data={editQuery} handleClose={() => setEditQuery(null)} handleSave={(data) => saveQuery(data)} />
            <ConfirmDialog open={Boolean(openDeleteSavedQuery)} yes={() => deleteSavedQuery(openDeleteSavedQuery)} no={() => setOpenDeleteSavedQuery(null)} title="Delete saved query?" description={<span>Do you really want to remove saved query <strong>"{openDeleteSavedQuery && openDeleteSavedQuery.name}"</strong> ?</span>} />
            <TriggerDialog open={openTriggerEdit} cancel={() => setOpenTriggerEdit(false)} query={filter.filter} />
        </Box >
    )
}




const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const StyledFab = styled((props) => <Fab {...props} />)(({ theme }) => ({
    position: 'absolute',
    bottom: 10,
    right: 10,
}));

const ButtonLoading = styled((props) => <Button {...props} />)(({ theme }) => ({
    '& .MuiButton-startIcon': { width: 20 }
}));

const StyledTabs = styled((props) => (<Tabs {...props} />))({
    minHeight: 'auto',
    '& .MuiButtonBase-root': {
        minHeight: 30
    },
});

const TableTitleSmall = styled((props) => <Typography {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
}));

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    minHeight: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    color: '#647188',
}));




export default React.memo(Explore);