import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { HelmetComponent } from '../lib/Components';
import { useQuery } from '../lib/Tools';
import moment from 'moment';
import BasicTable from './components/BasicTable';
import DateRange from './components/DateRange';
import ComputerIcon from '@mui/icons-material/Computer';
import PrintIcon from '@mui/icons-material/Print';
import Avatar from '@mui/material/Avatar';
import { post as ApiPost } from '../lib/Api';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const User = () => {
    const lastDays = 7;
    const query = useQuery();
    const user_name = query.get('user_name');
    const [dateRange, setDateRange] = useState({ from: moment().add(lastDays * -1, 'days').unix() });
    const [user, setUser] = useState({});
    const [tabIndex, setTabIndex] = useState(0);

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    useEffect(() => {
        async function fetchData() {
            const reponse = await ApiPost(`user`, { user_name });
            reponse.status === 200 && setUser(reponse.data);
        }
        fetchData();
    }, [user_name])

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>
                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>
                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack direction="column" spacing={1} >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ width: 47, height: 47 }} src={user.avatar} />
                                    <Stack spacing={0}>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>{user.firstname || user.lastname ? `${user.firstname} ${user.lastname}` : user_name}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{user_name}</Typography>
                                            <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{user.email}</Typography>
                                            <Typography component="div" variant="caption" color="textSecondary" sx={{ fontWeight: 500 }}>{user.phone}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <DateRange dateRange={dateRange} label={`Last ${lastDays} days`} onDateRangeSelect={(data) => setDateRange(data)} />
                                <Button onClick={() => filter()} startIcon={<Refresh />} size="small" color="primary">Refresh</Button>
                            </Stack>
                        </Stack>

                        <Stack sx={{ mb: 3 }}>
                            <StyledTabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                                <StyledTab label="Dashboard" />
                                <StyledTab label="Logs" />
                                <StyledTab label="Printing" />
                                <StyledTab label="File access" />
                                <StyledTab label="Profile" />
                            </StyledTabs>
                        </Stack>

                        {tabIndex === 0 ?
                            <Grid container spacing={3} >
                                <Grid item xs={12} md={6}>
                                    <BasicTable icon={<ComputerIcon />} config={{ url: 'data/data', dateRange, name: 'Endpoint Logins', user_name: query.get('user_name'), type: 'endpointLogins', column: ['auto', '180px', '180px'] }} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <BasicTable icon={<PrintIcon />} config={{ url: 'data/data', dateRange, name: 'Used Printers', user_name: query.get('user_name'), type: 'userPrinter', column: ['auto', '180px', '180px'] }} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                </Grid>
                                <Grid item xs={12} md={6}>

                                </Grid>
                            </Grid> : null}

                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}

const StyledTabs = styled((props) => (<Tabs {...props} />))({
    minHeight: 'auto',
    '& .MuiButtonBase-root': {
        minHeight: 30
    },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    minHeight: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    color: '#647188',
}));



export default React.memo(User);