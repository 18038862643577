import React, { } from 'react';
import { styled } from '@mui/material/styles';
import { theme } from '../../theme/authlify-mui-theme';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Stack from '@mui/material/Stack';

const RecentTable = ({ data, run }) => {
    return (
        <React.Fragment>
            <StyledRecentTable sx={{ display: { lg: 'block', xs: 'none' } }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" sx={{ width: 200 }}>LAST RUN</TableCell>
                            <TableCell component="th" sx={{ width: 100, textAlign: 'right', verticalAlign: 'top' }}></TableCell>
                            <TableCell component="th">FILTER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Moment format="DD.MM.YYYY HH:mm:ss" style={{ fontFamily: 'Roboto Mono', fontSize: 12, fontWeight: '400', }}>{row.timestamp}</Moment>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', verticalAlign: 'top' }}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => run(row)} startIcon={<PlayArrowIcon />}>RUN</Button>
                                </TableCell>
                                <TableCell>
                                    {row.filter}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledRecentTable>
            <StyledRecentTableMobile sx={{ display: { lg: 'none', xs: 'block' } }}>
                <Table>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Moment format="DD.MM.YYYY HH:mm:ss" style={{ fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12), fontWeight: '400', }}>{row.timestamp}</Moment>
                                        <Button sx={{ fontSize: theme.typography.pxToRem(11) }} size="small" variant="outlined" color="primary" onClick={() => run(row)} startIcon={<PlayArrowIcon />}>RUN</Button>
                                    </Stack>
                                    <Stack direction="column" spacing={1} sx={{ mt: 2, fontFamily: 'Roboto Mono', fontSize: theme.typography.pxToRem(12), }}>
                                        {row.filter}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledRecentTableMobile >
        </React.Fragment>
    )
}


const StyledRecentTable = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    '& tbody tr td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td:not(:nth-child(2)),& tbody tr td:not(:nth-child(2)) *': {
        fontSize: theme.typography.pxToRem(12),
    },
    '& tbody tr td:nth-child(2) button': {
        fontSize: theme.typography.pxToRem(11),
    },
}));


const StyledRecentTableMobile = styled((props) => <TableContainer {...props} />)(({ theme }) => ({


}));


export default RecentTable;