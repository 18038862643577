import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { post as ApiPost } from '../../lib/Api';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../theme/authlify-mui-theme';
import { getLevelInfo, notLevelEmpty } from '../../lib/Tools';

const BasicBarChart = ({ config, onBarMouseClick }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchData = async (config) => {
        setLoading(true);
        const response = await ApiPost(config.url, config);
        response.status === 200 && setData(response.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(config);
    }, [config])

    const tickFormat = (timestamp) => {
        const _datetime = new Date(timestamp);
        return moment(_datetime).format('HH:mm');
    }

    const serieClick = (serie) => {
        let _series = data.series.map(x => {
            if (x.key === serie.key) {
                x.hide = !x.hide;
            }
            return x;
        })
        setData({ ...data, series: _series })
    }

    const chartOnMouseUp = (e) => {
        if (e && (data.diff / 1000 / 60 > 1)) {
            onBarMouseClick && onBarMouseClick(e.activeLabel / 1000, data.diff);
        }
    }

    return (
        <StyledBoxContainer sx={{ minHeight: 217 }}>
            <Stack sx={{ p: 2 }} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="column" spacing={0}>
                    <BoxTitle>{config.name}</BoxTitle>
                    {config.description ? <BoxDescription color="textSecondary">{config.description}</BoxDescription> : null}
                </Stack>
                {data && data.series.length > 1 ? <Stack direction="row" spacing={2} alignItems="center" sx={{ display: { sm: 'block', xs: 'none' } }}>
                    {data.series.map((serie, index) => <ChartSerieButton startIcon={getLevelInfo(serie.level).icon} off={serie.hide ? 'off' : 'on'} size="small" key={index} onClick={() => serieClick(serie)}>
                        {notLevelEmpty(serie.level) ? getLevelInfo(serie.level).title : serie.name}
                    </ChartSerieButton>)}
                </Stack> : null}
            </Stack>
            {data && data.series.length > 1 ? <Stack direction="row" spacing={2} alignItems="center" textAlign="center" sx={{ display: { sm: 'none', xs: 'block' } }}>
                {data.series.map((serie, index) => <ChartSerieButton off={serie.hide ? 'off' : 'on'} size="small" key={index} onClick={() => serieClick(serie)}>
                    {serie.name}
                </ChartSerieButton>)}
            </Stack> : null}


            {data ? <ResponsiveContainer width={'100%'} height={150}>
                <BarChart
                    data={data.output}
                    margin={{
                        top: 4,
                        right: 30,
                        left: 18,
                        bottom: 8,
                    }}
                    onMouseUp={chartOnMouseUp}
                >
                    <XAxis dataKey="timestamp"
                        tickFormatter={tickFormat}
                        type='number'
                        tick={{ fontSize: 12 }}
                        //interval={20}
                        padding={{ left: 20, right: 20 }}
                        domain={['auto', 'auto']}
                        scale="time" />
                    <YAxis tick={{ fontSize: 12 }} tickCount={2} width={40} />
                    <Tooltip labelFormatter={t => new Date(t).toLocaleString()} />
                    {data.series.map((serie, index) => <Bar key={index} maxBarSize={40} hide={serie.hide} dataKey={serie.key} stackId="serie" fill={notLevelEmpty(serie.level) ? getLevelInfo(serie.level).color : (serie.fill || theme.palette.info.main)} name={serie.name} isAnimationActive={false} />)}


                </BarChart>
            </ResponsiveContainer> : null}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                open={loading}
            >
                <CircularProgress color="info" size={20} disableShrink />
            </Backdrop>
        </StyledBoxContainer>

    )
}
//  <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />

export default BasicBarChart;





const BoxTitle = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
}));

const BoxDescription = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    textTransform: 'uppercase'
}));

const StyledBoxContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
    background: theme.palette.background.paper,
    padding: 0,
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: '#EFF0F2',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: '0px 2px 13px rgba(0,0,0,0.04)',
    position: 'relative',
    height: '100%',
    minHeight: 430,
}));

const ChartSerieButton = styled((props) => <Button component="div" {...props} />)(({ theme, off }) => ({
    fontSize: theme.typography.pxToRem(11),
    textTransform: 'uppercase',
    color: off === 'off' ? theme.palette.text.disabled : theme.palette.text.primary,
    '& svg': {
        fill: off === 'off' ? theme.palette.text.disabled : ''
    }
}));