import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { HelmetComponent } from '../lib/Components';
import moment from 'moment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export default function Definitions() {
    const [dateRange, setDateRange] = useState({ from: moment().add(-1, 'days').unix(), to: moment().add(1, 'months').unix(), update: new Date().getTime() });

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>

                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>

                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <PeopleAltIcon style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Definitions</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <Button onClick={() => filter()} startIcon={<Refresh />} size="small" color="primary"  >Refresh</Button>
                            </Stack>
                        </Stack>


                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}