import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { HelmetComponent } from '../lib/Components';
import moment from 'moment';
import ComputerIcon from '@mui/icons-material/Computer';
import BasicTable from './components/BasicTable';
import ButtonLoading from './components/ButtonLoading';

const Endpoints = () => {
    const [dateRange, setDateRange] = useState({ from: moment().add(-1, 'days').unix(), to: moment().add(1, 'months').unix(), update: new Date().getTime() });

    const filter = () => {
        setDateRange({ ...dateRange, update: new Date().getTime() });
    }

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <HelmetComponent title="Dashboard Logs" />
            <Grid container spacing={3} sx={{ marginTop: { lg: 3, xs: 0 } }}>

                <Grid item xs sx={{ display: { lg: 'none', xs: 'none' } }}>
                </Grid>

                <Grid item xs={12} xl={12}>
                    <Container maxWidth="xl">
                        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginBottom: 2 }}>
                            <Stack >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <ComputerIcon style={{ marginTop: -3 }} />
                                    <Typography component="div" variant="h5" sx={{ fontWeight: 500 }}>Endpoints</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <ButtonLoading onClick={() => filter()} title="Refresh" />
                            </Stack>
                        </Stack>

                        <Grid container spacing={3} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={12}>
                                <BasicTable config={{ minHeight: 430, url: 'data/data', dateRange, name: 'All Endpoints', type: 'endpoints', column: ['auto', 'auto', '120px', '180px'] }} />
                            </Grid>
                        </Grid>

                    </Container>
                </Grid>
            </Grid >
        </Box >
    )
}


export default React.memo(Endpoints);