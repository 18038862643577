import Moment from 'react-moment';
import React, { useRef, useEffect } from 'reactn';
import io from 'socket.io-client';
import { API_HOST } from './Api';
import Chip from '@material-ui/core/Chip';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import WebHook from '../assets/WebHook.svg';
import Box from '@material-ui/core/Box';
import orange from '@material-ui/core/colors/orange';
import { grey, red } from '@material-ui/core/colors';
import { theme } from '../theme/authlify-mui-theme';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useLocation } from 'react-router';
import moment from 'moment';

let _SOCKET = null;
export const socketio = () => {
    if (!_SOCKET) {
        _SOCKET = io(API_HOST);
    }
    return _SOCKET;
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const modifier = (value, type) => {
    switch (type) {
        case 'bytes':
            return formatBytes(value);
        case 'date':
        case 'datetime':
        case 'timestamp with time zone':
            return <Moment format="DD.MM.YYYY HH:mm:ss">{value}</Moment>
        default:
            return value;
    }
}
export const numberWithSpaces = (x) => {
    x = parseInt(x);
    if (Number.isFinite(x)) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
        return x;
    }
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const getFromBySecondsBack = (seconds) => {
    return moment().add(seconds * -1, 'seconds').unix();
}

export const timeTool = (value) => {
    if (typeof value === 'undefined' || (typeof value === 'number' && String(value).length < 10) || !moment(value).isValid()) {
        value = new Date();
    } else if ((typeof value === 'number' && String(value).length === 10)) {
        value = new Date(value * 1000);
    } else {
        value = new Date(value);
    }

    let format = 'LLL';
    navigator.language === 'cs-CZ' && (format = 'DD.MM.YYYY HH:mm');

    return {
        miliseconds: () => {
            return value.getTime();
        },
        second: () => {
            return moment(value).unix()
        },
        picker: () => {
            return moment(value).format('yyyy-MM-DDTHH:mm');
        },
        datetimeUTC: () => {
            return moment(value).toISOString();
        },
        datetimeFormat: () => {
            return moment(value).format(format);
        }
    }
}

export const fromMidnight = () => {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return {
        getUnixTime: () => {
            return now.getTime();
        },
        getTime: () => {
            return now.getTime() / 1000;
        }
    }
}

export const getLoginType = (type) => {
    switch (type) {
        case 10:
            return 'Remote Interactive';
        case 11:
            return 'Cached Interactive';
        case 2:
            return 'Interactive';

        default:
            return '-';
    }
}

export const formatPowershellLog = (text) => {

}

export const formatString = (text) => {
    try {
        text = replaceIP(text);
        text = formatDN(text);
        return text;
    } catch {
        return text;
    }
}

const replaceIP = (text) => {
    try {
        //{"event_id":20292}
        let matchData = text.match(/(IP#(.*)#)/);
        let matchDataItem = matchData[2];
        if (!matchDataItem) return text;

        const result = /\d+/.exec(matchDataItem);

        if (!result) { return text; }

        let value = result[0];

        const ip = [
            value & 0xff,
            (value >> 8) & 0xff,
            (value >> 16) & 0xff,
            (value >> 24) & 0xff
        ].join('.');

        text = text.replaceAll(matchData[0], ip)
        return text;
    } catch {
        return text;
    }
}

export const PrettyPrintJson = React.memo(({ data }) => {
    const _data = { ...data };
    delete _data.definition;
    return (
        <div><pre style={{ fontFamily: 'Roboto Mono', fontSize: 12, marginTop: 0 }}>
            {JSON.stringify(_data, null, 2)}
        </pre></div >
    )
});

export const getLevelDescription = (level) => {
    switch (level) {
        case 5:
            return 'Critical'
        case 4:
            return 'High'
        case 3:
            return 'Average'
        case 2:
            return 'Warning'
        default:
            return 'Information'
    }
}

export const getLink = (to) => {
    // TODO : precist z cookie pokud je...
    let urlPath = window.location.pathname.substring(1);
    let splitPath = urlPath.split('/');
    if (splitPath.length > 1) {
        return `/${splitPath[0]}/${to}`;
    } else {
        return to;
    }
}

export const notLevelEmpty = (value) => {
    return value !== undefined && String(value) && value !== null;
}

export const getLevelInfo = (level) => {
    switch (level) {
        case 5:
            return { color: theme.palette.info.main, title: 'Verbose', icon: <InfoIcon fontSize="small" htmlColor={theme.palette.info.main} /> }
        case 4:
            return { color: theme.palette.info.main, title: 'Information', icon: <InfoIcon fontSize="small" htmlColor={theme.palette.info.main} /> }
        case 3:
            return { color: theme.palette.warning.main, title: 'Warning', icon: <WarningIcon fontSize="small" htmlColor={theme.palette.warning.main} /> }
        case 2:
            return { color: theme.palette.error.main, title: 'Error', icon: <ErrorIcon fontSize="small" htmlColor={theme.palette.error.main} /> }
        case 1:
            return { color: theme.palette.critical.main, title: 'Critical', icon: <ErrorIcon fontSize="small" htmlColor={theme.palette.critical.main} /> }
        case 0:
            return { color: theme.palette.LogAlways.main, title: 'Log Always', icon: <InfoIcon fontSize="small" htmlColor={theme.palette.LogAlways.main} /> }
        default:
            return { color: 'rgba(9,30,66,1)', title: 'Other', icon: <InfoIcon fontSize="small" htmlColor={'rgba(9,30,66,1)'} /> }
    }
}

export const getSeverenityInfo = (level) => {
    switch (level) {
        case 1:
            return { color: theme.palette.info.light, title: 'Low', icon: <InfoIcon fontSize="small" htmlColor={'rgba(9,30,66,0.1)'} /> }
        case 2:
            return { color: theme.palette.primary.light, title: 'Medium', icon: <WarningIcon fontSize="small" htmlColor={theme.palette.warning.light} /> }
        case 3:
            return { color: theme.palette.error.light, title: 'High', icon: <ErrorIcon fontSize="small" htmlColor={theme.palette.error.light} /> }
        case 4:
            return { color: theme.palette.error.dark, title: 'Critical', icon: <ErrorIcon fontSize="small" htmlColor={theme.palette.error.dark} /> }
        default:
            return { color: theme.palette.info.light, title: 'Low', icon: <InfoIcon fontSize="small" htmlColor={'rgba(9,30,66,0.1)'} /> }
    }
}

export const formatDN = (text) => {
    try {
        let split = text.split(',');

        if (split.length > 0) {
            let dc = [];
            let ou = [];
            let name = "";
            let replaceDN = [];
            for (const item of split) {
                if (item.toLowerCase().startsWith('dc=')) {
                    let value = item.split('=')[1];

                    if (value.indexOf(' ') > 0) {
                        let v = value.substr(0, value.indexOf(' '));
                        dc.push(v)
                        replaceDN.push(item.substr(0, item.indexOf(' ')));
                    } else {
                        dc.push(value)
                        replaceDN.push(item);
                    }
                }
                if (item.toLowerCase().startsWith('ou=')) {
                    let value = item.split('=')[1];
                    ou.push(value)
                    replaceDN.push(item);
                }
                if (item.toLowerCase().startsWith('cn=')) {
                    name = item.split('=')[1];
                    replaceDN.push(item);
                }
            }
            //var domain = dc.join('.');
            let organizationUnit = ou.join('/');
            let DN = replaceDN.join(',');
            if (name || organizationUnit) {
                let format = `${name}`;
                if (organizationUnit) {
                    format += ` (${organizationUnit})`;
                }

                return text.replace(DN, format);
            } else {
                return text;
            }
        }
        return text;
    } catch {
        return text;
    }
}

export const ActionIcon = ({ action, ...props },) => {
    switch (action.type) {
        case 'email':
            return <Chip size="small" variant="outlined" {...props} icon={<AlternateEmailIcon />} label={action.value} />
        case 'webhook':
            return <Chip size="small" variant="outlined" {...props} icon={<img alt='WebHook' src={WebHook} />} label={action.value} />
        default:
            return <Box></Box>
    }
}

export const Colors = {
    warning: orange[300],
}

export const LevelColors = {
    critial: red[900],
    error: red[500],
    warning: orange[100],
    information: grey[100]
}


export const getEventFormatted = (event, lang = 'en') => {
    try {
        const replacements = event.data;
        let text = event.info[`lang_${lang}`].formatted;
        return text.replace(/{(\w+)}/g, (text, placeholderWithoutDelimiters) => replacements.hasOwnProperty(placeholderWithoutDelimiters) ? replacements[placeholderWithoutDelimiters] : text);
    } catch {
        return null;
    }
}

export const formatStringLang = (text, name, lang, data) => {
    try {
        data = Object.entries(data).map(([key, value], index) => {
            return [index] = value
        })
        if (text[lang] && text[lang][name]) {
            text = text[lang][name];
        } else {
            text = text['en'][name];
        }

        return text.replace(/{(\w+)}/g, (text, placeholderWithoutDelimiters) => data.hasOwnProperty(placeholderWithoutDelimiters) ? data[placeholderWithoutDelimiters] : text);
    } catch (error) {
        return null;
    }
}

export const getEventName = (event, lang = 'en') => {
    try {
        let text = event.info[`lang_${lang}`];
        if (text) {
            return event.info[`lang_${lang}`].name;
        } else {
            return event.info[`lang_en`].name;
        }
    } catch {
        return '';
    }
}


export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}