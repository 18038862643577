import React, { useState, useEffect } from 'reactn';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { post as ApiPost } from '../lib/Api';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FilterListIcon from '@material-ui/icons/FilterList';
import { theme } from '../theme/authlify-mui-theme';
import ClearIcon from '@material-ui/icons/Clear';
import TablePagination from '@material-ui/core/TablePagination';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Moment from 'react-moment';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { ActionIcon } from '../lib/Tools';
import { Helmet } from 'react-helmet-async';
import Checkbox from '@material-ui/core/Checkbox';

const TableComponent = React.memo(({ data, modal, selectedItems }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const selectClick = (event, row) => {
        const { _id, name, action } = row;
        let newSelected = selected;
        const exists = selected.filter(x => x._id === row._id).length > 0;
        if (event.target.checked && !exists) newSelected.push({ _id, name, action });
        if (!event.target.checked && exists) newSelected = newSelected.filter(x => x._id !== row._id);
        if (selectedItems) selectedItems(newSelected);
        setSelected(newSelected);
    }

    return (
        <Box>
            <Helmet>
                <title>Authlify | Action</title>
            </Helmet>
            <TableContainer>
                <Table aria-label="event list table" size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {modal ? <TableCell style={{ width: 50 }}></TableCell> : null}
                            <TableCell style={{ width: 180 }}>Created</TableCell>
                            <TableCell style={{ width: 220 }}>Name</TableCell>
                            <TableCell>Target</TableCell>
                            {!modal ? <React.Fragment><TableCell style={{ width: 110, textAlign: "center" }}>References</TableCell>
                                <TableCell style={{ width: 320 }}>Last update</TableCell></React.Fragment> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.data && data.data.map((row, index) => (
                            <TableRow key={index} >
                                {modal ? <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={row.select}
                                        inputProps={{ 'aria-labelledby': index }}
                                        onChange={(event) => selectClick(event, row, index)}
                                    />
                                </TableCell> : null}
                                <TableCell>
                                    <Moment format="DD.MM.YYYY HH:mm:ss">{row.created}</Moment>
                                </TableCell>
                                <TableCell >
                                    <Box>
                                        <Link component={NavLink} to={`action/${row._id}`}> {row.name}</Link>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {row.action.map((a, index) =>
                                        <ActionIcon key={index} action={a} style={{ marginRight: 10 }} />
                                    )}
                                </TableCell>
                                {!modal ? <React.Fragment><TableCell style={{ textAlign: "center" }}>
                                    {row.references}
                                </TableCell>
                                    <TableCell>
                                        {row.lastUpdate ? <Moment format="DD.MM.YYYY HH:mm:ss">{row.lastUpdate}</Moment> : '-'}
                                        {row.lastUpdateUser ? <Box component="span" ml={1}>({row.lastUpdateUser})</Box> : null}
                                    </TableCell>
                                </React.Fragment> : null}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
});

export default function Actions({ modal, onData, selectedItems }) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ limit: 50, skip: 0, name: '' });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePage({ page: newPage, rowsPerPage });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        changePage({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    const changePage = async (a) => {
        setFilter({ ...filter, limit: a.rowsPerPage, skip: a.page * a.rowsPerPage })
    }

    const fetchData = async (filter) => {
        const response = await ApiPost('actions', filter);
        if (data.length === 0 && onData) {
            onData();
        }
        response.status === 200 && setData(response.data);
    }

    useEffect(() => {
        const fetchDataLocal = async (filter) => {
            const response = await ApiPost('actions', filter);
            response.status === 200 && setData(response.data);
        }
        fetchDataLocal(filter)
    }, [filter])

    const changeFilter = (event, name, value) => {
        const _name = name || event.target.name;
        const _value = value || event.target.value;
        setPage(0);
        setFilter({ ...filter, [_name]: _value })
    }
    return (
        <Box minHeight="100%" overflow="auto">
            {!modal ? <Grid item container alignItems="center" direction="row" className={classes.header} wrap="nowrap">
                <Grid item container direction="row" alignItems="center">
                    <SendRoundedIcon style={{ marginRight: theme.spacing(1) }} />
                    <Typography variant="h6" component="h1" className={classes.h1}>Actions <Typography color="textSecondary" variant="body2" component="span">({data.count})</Typography></Typography>

                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                    <Button component={NavLink} to={`action`} color="primary" size="small" startIcon={<AddBoxIcon />}>Create action</Button>
                </Grid>
            </Grid> : null}
            <Grid container item justifyContent="space-between" alignItems="center" className={classes.filter}>
                <Box >
                    <Grid container item alignItems="center">
                        <FilterListIcon style={{ marginRight: theme.spacing(1) }} />
                        <Typography variant="subtitle2" component="span" style={{ marginRight: theme.spacing(2) }}>Filter</Typography>
                        <TextField
                            size="small"
                            label="Name"
                            id="name"
                            variant="outlined"
                            value={filter.eventID}
                            name="name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="All"
                            onChange={(event) => changeFilter(event)}
                            style={{ marginRight: theme.spacing(3) }}
                        />
                        <Box ml={-2}>
                            <Fade in={Boolean(filter.name || filter.severenity > -1)}>
                                <Button color="primary" size="small" onClick={() => setFilter({ limit: 50, skip: 0, name: '' })} startIcon={<ClearIcon />}>Clear filter</Button>
                            </Fade>
                        </Box>
                    </Grid>
                </Box>
                <Box>
                    <Button onClick={() => fetchData(filter)} color="primary" startIcon={<RefreshIcon />} size="small" >Refresh</Button>
                </Box>
            </Grid>
            <TableComponent data={data} modal={modal} selectedItems={selectedItems} />
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={data.count || 20}
                rowsPerPage={rowsPerPage}
                page={page || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box >
    )
};

const useStyles = makeStyles((theme) => ({
    header: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    filter: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    h1: {
        fontWeight: 500,
        marginRight: 10,
    },
    table: {
        minWidth: 650,
        '& tbody tr': {
            backgroundColor: 'white'
        }
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 200,
    },
}))