import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { post as ApiPost } from '../../lib/Api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { numberWithSpaces } from '../../lib/Tools';

const SimpleInfo = ({ config }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchData = async (config) => {
        setLoading(true);
        const response = await ApiPost(config.url, config);
        response.status === 200 && setData(response.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(config);
    }, [config])

    return (
        <StyledBoxContainer sx={{ minHeight: 217, display: 'flex', flexDirection: 'column' }}>
            <Stack sx={{ p: 2, position: 'absolute' }} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="column" spacing={0} sx={{}}>
                    <BoxTitle>{config.name}</BoxTitle>
                    {config.description ? <BoxDescription color="textSecondary">{config.description}</BoxDescription> : null}
                </Stack>
            </Stack>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                {data && data.series.map((serie, index) => <Stack key={index} alignItems="center" direction="column" spacing={0}>
                    {data.data.map((row, idx) => <BoxText key={idx}>{numberWithSpaces(row[serie.key])}</BoxText>)}
                    <BoxTitle color="textSecondary">{serie.name}</BoxTitle>
                </Stack>
                )}
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                open={loading}
            >
                <CircularProgress color="info" size={20} />
            </Backdrop>

        </StyledBoxContainer>

    )
}


export default SimpleInfo;



const BoxText = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(60),
    textTransform: 'uppercase'
}));


const BoxTitle = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
}));

const BoxDescription = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    textTransform: 'uppercase'
}));

const StyledBoxContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
    background: theme.palette.background.paper,
    padding: 0,
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: '#EFF0F2',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: '0px 2px 13px rgba(0,0,0,0.04)',
    position: 'relative',
    height: '100%',
    minHeight: 430,
}));