import React, { useEffect, useState } from 'reactn';
import { Box, Grid, Typography, Button, Link, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../theme/authlify-mui-theme';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { post as ApiPost } from '../lib/Api';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function EditAction() {
    const classes = useStyles();
    const { id, accountID } = useParams();
    const [data, setData] = useState();
    const [goTo, setGoTo] = useState();
    const [openDelete, setOpenDelete] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await ApiPost(`action`, { id });
            response.status === 200 && setData(response.data);
        }
        if (id) {
            fetchData();
        } else {
            setData({ name: '', action: [{ type: 'email', value: '' }] });
        }

    }, [id])

    const saveForm = async () => {
        if (data.action.length === 0) return;
        for (const action of data.action) {
            if (!action.value) return;
        }
        if (!data.name) return;
        const response = await ApiPost(`action/save`, data);
        if (response.status === 200) {
            setGoTo(`/${accountID}/actions`)
        }
    }

    const updateForm = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value })
    }
    const updateFormAction = (event, index) => {
        const { name, value } = event.target;
        let action = data.action;
        action[index][name] = value;
        setData({ ...data, action });
    }

    const removeItem = (index) => {
        let action = data.action;
        action.splice(index, 1);
        setData({ ...data, action });
    }

    const deleteItem = async () => {
        const response = await ApiPost(`action/delete`, data);
        if (response.status === 200) {
            setGoTo(`/${accountID}/actions`)
        }
    }

    const addAction = () => {
        let action = data.action;
        action.push({ type: 'email', value: '' });
        setData({ ...data, action });
    }

    if (goTo) {
        return <Redirect to={goTo} />
    }

    return (
        <Box minHeight="100%" overflow="auto">
            <Dialog
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete this action?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Do you really want to remove the <strong>"{data && data.name}"</strong> action from all triggers?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)} variant="outlined" color="primary" size="small">
                        No
                    </Button>
                    <Button onClick={() => deleteItem()} variant="contained" color="secondary" size="small">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item container alignItems="center" direction="row" className={classes.header} wrap="nowrap">
                <Grid item container direction="row" alignItems="center">
                    <Link component={NavLink} to={`/${accountID}/actions`}>
                        <ArrowBackIcon style={{ marginRight: theme.spacing(1) }} />
                    </Link>

                    <Typography variant="h6" component="h1">Edit Actions</Typography>

                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                    <Button startIcon={<SaveIcon />} variant="contained" color="primary" size="small" onClick={saveForm} style={{ marginRight: theme.spacing(1) }}>SAVE</Button>
                    <Button component={NavLink} to={`/${accountID}/actions`} variant="outlined" size="small">CANCEL</Button>
                    {id ? <Button startIcon={<DeleteIcon />} variant="text" size="small" color="primary" onClick={() => setOpenDelete(true)} style={{ marginLeft: theme.spacing(3) }}>DELETE</Button> : null}
                </Grid>
            </Grid>
            {data ?
                <Paper className={classes.paper} square={true} elevation={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <form noValidate autoComplete="off">
                                <Box pb={1}>
                                    <TextField
                                        size="small"
                                        label="Name"
                                        required
                                        id="name"
                                        variant="outlined"
                                        style={{ width: '100%', maxWidth: 500 }}
                                        name="name"
                                        value={data.name}
                                        error={!data.name}
                                        helperText={!data.name && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Name is required</span>}
                                        onChange={(event) => { updateForm(event) }} />
                                </Box>

                                <Divider className={classes.divider} />
                                <Typography variant="h6" component="h2" className={classes.h2}>Target</Typography>
                                <Box maxWidth={500}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" style={{ fontWeight: 700 }}>Type</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body2" style={{ fontWeight: 700 }}>Value *</Typography>
                                        </Grid>
                                    </Grid>
                                    {data.action.map((action, index) => <Grid container spacing={3} key={`id-${index}`} >
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                                                <Select
                                                    id={`type-${index}`}
                                                    value={action.type}
                                                    name="type"
                                                    onChange={(event) => { updateFormAction(event, index) }}
                                                >
                                                    <MenuItem value={'email'}>E-mail</MenuItem>
                                                    <MenuItem value={'webhook'}>WebHook</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container xs={8} alignItems="flex-start">
                                            <TextField
                                                size="small"
                                                required
                                                error={!action.value}
                                                helperText={!action.value && <span><InfoIcon fontSize="small" style={{ marginRight: 5, float: 'left' }} />Value is required</span>}
                                                id={`value-${index}`}
                                                variant="outlined"
                                                name="value"
                                                value={action.value}
                                                style={{ width: 'calc(100% - 50px)' }}
                                                onChange={(event) => { updateFormAction(event, index) }} />
                                            {index > 0 ? <IconButton color="primary" size="small" component="span" style={{ marginLeft: 4, marginTop: 6 }} onClick={() => removeItem(index)} >
                                                <CloseIcon />
                                            </IconButton> : null}
                                        </Grid>
                                    </Grid>



                                    )}
                                </Box>
                                <Box mt={3} mb={3}>
                                    <Button startIcon={<AddIcon />} style={{ width: 140 }} variant="outlined" color="primary" size="small" onClick={addAction}>Add action</Button>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item xs={4}>

                        </Grid>
                    </Grid>
                </Paper> : null
            }
        </Box >
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        borderBottom: '1px solid #e0e0e0'
    },
    header: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    filter: {
        padding: 13,
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0'
    },
    h2: {
        fontWeight: 700,
        marginRight: 10,
        fontSize: 17,
        paddingBottom: theme.spacing(2)
    },
    table: {
        minWidth: 650,
        '& tbody tr': {
            backgroundColor: 'white'
        }
    },
    formControl: {
        marginRight: theme.spacing(3),
        minWidth: 200,
    },
}))