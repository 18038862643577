import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { post as ApiPost } from '../../lib/Api';
import { getLevelInfo, numberWithSpaces, getLink, notLevelEmpty, getLoginType } from '../../lib/Tools';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import Moment from 'react-moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FindInPage from '@mui/icons-material/FindInPage';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import { Waypoint } from 'react-waypoint';

const getIcon = (icon) => {
    switch (icon) {
        case 'filter':
            return <FindInPage fontSize="small" />
        case 'right':
            return <ChevronRightIcon fontSize="small" />
        default:
            return <OpenInNewIcon />
    }
}



const BasicTable = ({ config, icon }) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await ApiPost(config.url, config);
            response.status === 200 && setData(response.data);
            setLoading(false);
        }
        fetchData();
    }, [config])


    const getMaxWidth = (index) => {
        if (config.column && config.column[index]) {
            return config.column[index];
        } else {
            return null
        }
    }

    return (
        <StyledBoxContainer limit={config.limit} minHeight={config.minHeight}>
            <Stack sx={{ p: 2 }} direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="column" spacing={0}>
                    <Stack direction="row" spacing={1} alignItems="center" >
                        {notLevelEmpty(config.level) ? getLevelInfo(config.level).icon : (icon ? icon : null)}
                        <BoxTitle>{config.name}</BoxTitle>
                    </Stack>
                    {config.description ? <BoxDescription sx={{ mt: 1 }} color="textSecondary">{config.description}</BoxDescription> : null}
                </Stack>
            </Stack>
            {data && data.data ?
                <Box sx={{ p: 1 }}>
                    <StyledTable sx={{ display: { lg: 'block', xs: 'none' } }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {data.series.map((serie, index) => <TableCell key={index} style={{ width: getMaxWidth(index), textAlign: serie.textAlign ? serie.textAlign : null }}>{serie.name}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.data.map((row, indexRow) => (
                                    <TableRow key={indexRow} hover={true}>
                                        {data.series.map((serie, index) => <TableCell key={index} style={{ fontFamily: 'Roboto Mono', width: getMaxWidth(index) }}>
                                            <ColumnValue serie={serie} row={row} width={getMaxWidth(index)} />

                                        </TableCell>)}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {data.data.length === 0 ?
                            <StyledEmptyBox >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography color="success.main" variant="caption">{config.noRow || 'Empty'}</Typography>
                                </Stack>
                            </StyledEmptyBox>
                            : null}
                    </StyledTable>

                    <StyledTableMobile sx={{ display: { lg: 'none', xs: 'block' } }}>
                        <Table size="small">
                            <TableBody>
                                {data.data && data.data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell >
                                            {data.series.map((serie, index) => <Stack key={index} direction="row" spacing={1} sx={{ mt: 1 }}>
                                                <Typography component="div" sx={{ fontWeight: 500, mt: '1px' }}>{serie.name}:</Typography>
                                                <Typography component="div" sx={{ fontFamily: 'Roboto Mono' }}>{row[serie.key]}</Typography>
                                            </Stack>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {data.data.length === 0 ?
                            <StyledEmptyBox >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography color="success.main" variant="caption">{config.noRow || 'Empty'}</Typography>
                                </Stack>
                            </StyledEmptyBox>
                            : null}
                    </StyledTableMobile>


                </Box>
                : null}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgb(255 255 255 / 50%)', position: 'absolute', top: -8, right: 0 }}
                open={loading}
            >
                <CircularProgress color="info" size={20} disableShrink />
            </Backdrop>
        </StyledBoxContainer>
    )
}

export default BasicTable;

const MiniChart = ({ pct }) => {

    return (
        <StyledMiniContainer direction="row" spacing={1} alignItems="center" sx={{ ml: 1, mr: 1 }}>
            <Box sx={{ width: '30px' }}>{`${pct}%`}</Box>
            <StyledMiniChart>
                <Box sx={{ width: `${pct}%` }} />
            </StyledMiniChart>
        </StyledMiniContainer>

    )
}


const ColumnValue = ({ row, serie, width }) => {

    const title = _.get(row, serie.key);


    const formatText = (text, serie) => {
        if (serie.noFormat) return text;
        try {
            if (serie.type === 'login_type') {
                return getLoginType(text);
            }
            if (!isNaN(Number(text))) {
                const num = parseInt(text);
                return numberWithSpaces(num);
            } else {
                return text;
            }
        } catch {
            return text;
        }
    }

    const linkTo = (serie, row) => {
        let link = null;
        if (serie.link && serie.link.to) {
            link = serie.link.to;
            if (serie.link.query) {
                link += '?' + serie.link.query.map(x => `${x}=${row[x]}`).join('&');
            } else if (serie.link.id) {
                link += '/dd'
            } else { }
        }
        return link;
    }

    const _linkTo = linkTo(serie, row);

    if (serie.type === 'chart') {
        return <MiniChart pct={row[serie.key]} />
    }

    if (_linkTo) {
        return (
            <NavLink to={getLink(_linkTo)} style={{ maxWidth: serie.noShrink ? 'initial' : null, textAlign: serie.textAlign ? serie.textAlign : null, padding: serie.icon ? 0 : null }}>
                {serie.type === 'datetime' ? <Moment format="DD.MM.YYYY HH:mm:ss">{title}</Moment> :
                    serie.icon ? getIcon(serie.icon) : formatText(title, serie)
                }
            </NavLink>
        )
    } else {
        return <Box component="span" style={{ width: width ? width : null, maxWidth: serie.noShrink ? 'initial' : null, textAlign: serie.textAlign ? serie.textAlign : null }}>
            {serie.type === 'datetime' ? <Moment format="DD.MM.YYYY HH:mm:ss">{title}</Moment> :
                serie.icon ? getIcon(serie.icon) : formatText(title !== null ? title : '-', serie)
            }
        </Box>
    }

}

const StyledMiniContainer = styled((props) => <Stack {...props} />)(({ theme }) => ({

}));

const StyledMiniChart = styled((props) => <Box {...props} />)(({ theme }) => ({
    height: 10,
    flex: 1,
    backgroundColor: 'rgb(0 40 60 / 10%)',
    borderRadius: '0 2px 2px 0',
    overflow: 'hidden',
    '& div': {
        height: 10,
        backgroundColor: '#43a5f5',
        borderRadius: '0 2px 2px 0',
    }
}));

const StyledEmptyBox = styled((props) => <Box {...props} />)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    margin: 16
}));

const StyledTable = styled((props) => <TableContainer {...props} />)(({ theme, noShrink }) => ({
    '& table': {
        tableLayout: 'fixed'
    },
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    '& tbody tr': {
        verticalAlign: 'top',

    },
    '& tbody tr td a,& tbody tr td span': {
        display: 'block',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textDecoration: 'none',
        color: theme.palette.text.primary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        //maxWidth: 200
    },
    '& tbody tr td': {
        padding: theme.spacing(0),
        //paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td,& tbody tr td *:not(svg)': {
        fontSize: theme.typography.pxToRem(12),
        verticalAlign: 'middle'
    },
    '& tbody tr:last-child td': {
        borderBottom: 0,
        //paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td a:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    }
}));

const StyledTableMobile = styled((props) => <TableContainer {...props} />)(({ theme }) => ({
    '& thead tr th': {
        fontSize: theme.typography.pxToRem(11),
        color: '#647188',
        whiteSpace: 'nowrap',
        minWidth: 90
    },
    '& tbody tr': {
        verticalAlign: 'top',

    },
    '& tbody tr td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5)
    },
    '& tbody tr td,& tbody tr td *': {
        fontSize: theme.typography.pxToRem(12),
    }
}));

const BoxTitle = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    textTransform: 'uppercase'
}));

const BoxDescription = styled((props) => <Typography component="div" {...props} />)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    textTransform: 'uppercase'
}));

const StyledBoxContainer = styled((props) => <Box {...props} />)(({ theme, limit, minHeight }) => ({
    background: theme.palette.background.paper,
    padding: 0,
    borderRadius: 8,
    overflow: 'hidden',
    borderColor: '#EFF0F2',
    borderWidth: 1,
    borderStyle: 'solid',
    boxShadow: '0px 2px 13px rgba(0,0,0,0.04)',
    position: 'relative',
    height: '100%',
    minHeight: minHeight ? minHeight : limit ? 430 : null
}));


